import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
} from "design-react-kit";
import { useTranslate } from "react-admin";
import Link from "./Link";
import PropTypes from "prop-types";
const Breadcrumbs = ({ items, location: { pathname, search } }) => {
  const translate = useTranslate();
  return (
    <Container tag="section" className="px-4 my-4">
      <Row>
        <Col>
          <nav aria-label="breadcrumb" className="breadcrumb-container">
            <Breadcrumb aria-label="breadcrumb" listTag="ol" tag="nav">
              {items.map((b, i) => {
                const fullURI = `${pathname}${search}`;
                const active =
                  b.to === "/"
                    ? pathname === b.to
                    : pathname.indexOf(b.to) === 0 ||
                      fullURI.indexOf(b.to) === 0;

                return (
                  <BreadcrumbItem key={i} tag="li" active={active}>
                    {active && translate(b.label)}
                    {!active && <Link to={b.to}>{translate(b.label)}</Link>}
                    {i < items.length - 1 && (
                      <span className="separator">/</span>
                    )}
                  </BreadcrumbItem>
                );
              })}
            </Breadcrumb>
          </nav>
        </Col>
      </Row>
    </Container>
  );
};
Breadcrumbs.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Breadcrumbs;
