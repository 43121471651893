import React, { useMemo } from "react";

import Breadcrumbs from "../../components/Breadcrumbs";
import List from "./List";
import Loader from "../../components/Loader";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import { get } from "lodash";
import usePage from "../usePage";

const TagPage = (props) => {
  const tag = get(props, "match.params.tag");
  const page = usePage("tag", { tag }, false);
  const list = useMemo(() => get(page, "results", []), [page]);

  if (page === false) {
    return <Loader />;
  }

  return (
    <>
      <Breadcrumbs
        {...props}
        items={[
          {
            to: "/",
            label: "website.navbar.home",
          },
          {
            to: "/contenuti-in-evidenza",
            label: "website.navbar.tag",
          },
          {
            label: `#${tag}`,
            to: "#",
          },
        ]}
      />
      <Section className="pt-0">
        <SectionTitle title={`#${tag}`} />
        <List list={list} />
      </Section>
    </>
  );
};

export default TagPage;
