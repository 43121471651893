export default (csv, filename) => {
  const fakeLink = document.createElement("a");
  fakeLink.style.display = "none";
  document.body.appendChild(fakeLink);
  const BOM = "\uFEFF";
  const blob = new Blob([BOM + csv], { type: "text/csv;chartset=utf-8" });

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
  } else {
    fakeLink.setAttribute("href", URL.createObjectURL(blob));
    fakeLink.setAttribute("download", `${filename}.csv`);
    fakeLink.click();
  }
};
