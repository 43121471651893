import { Button, Col, Row } from "design-react-kit";
import React from "react";
import PropTypes from "prop-types";
import TableFilter from "./TableFilter";
import { useTranslate} from "react-admin";

const TableFilters = ({
  filters,
  searchable = false,
  exportable = false,
  onChange,
  onClear,
  onExport,
  changes, 
  setChanges
}) => {

  const translate = useTranslate();
  
  const handleChange = (filter, value) => {
    let obj = {}
    obj[filter.id] = value
    if(filter.id === Object.keys(obj)[0] && filter.def_type === "assessorato") {
      setChanges(obj);
    } else {
      setChanges({
        ...changes,
        [filter.id]: value,
      });
    }
  };
  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(changes);
  };
  const handleClear = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setChanges("");
    onChange({});
    onClear();
  };
  const handleExport = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onExport();
  };
  return (
    <Row tag="div" className="table-search-form">
      {searchable && (
        <Col lg={8} md={9} xs={12}>
          <TableFilter
            filter={{
              name: translate("website.search_keyword"),
              def_type: "text",
              id: "*",
            }}
            onChange={handleChange}
            value={(changes && changes["*"]) || ""}
          />
        </Col>
      )}
      {filters.map((filter, index) => (
        <Col key={index} lg={4} md={6} xs={12}>
          <TableFilter
            filter={filter}
            onChange={handleChange}
            value={(changes && {label: changes[filter.id], value: changes[filter.id]}) || ""}
          />
        </Col>
      ))}
      <Col lg={12}>
        <Button color="primary" onClick={handleSubmit}>
          {translate("website.search")}
        </Button>

        {exportable && (
          <Button color="secondary" onClick={handleExport}>
            {translate("website.export")}
          </Button>
        )}
        <Button color="secondary" onClick={handleClear}>
          {translate("website.clear_filters")}
        </Button>
      </Col>
    </Row>
  );
};

TableFilters.propTypes = {
  searchable: PropTypes.bool,
  onChange: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      def_type: PropTypes.string.isRequired,
    })
  ),
};

export default TableFilters;
