import React from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";
import { Icon } from "design-react-kit";
import classNames from "classnames";

const SelectContainer = ({ children, ...props }) => {
  return (
    <div>
      <components.SelectContainer {...props}>
        {children}
      </components.SelectContainer>
    </div>
  );
};

SelectContainer.propTypes = {
  children: PropTypes.node,
};

const Option = (props) => {
  return (
    <div className="select-pill text-primary">
      <components.Option {...props} />
    </div>
  );
};
const MenuList = ({ children, ...props }) => {
  return (
    <div>
      <components.MenuList {...props}>{children}</components.MenuList>
    </div>
  );
};

MenuList.propTypes = {
  children: PropTypes.node,
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon="it-arrow-down-triangle" style={{ ariaHidden: true }} />
    </components.DropdownIndicator>
  );
};

const GroupHeading = (props) => (
  <div>
    <components.GroupHeading {...props} />
  </div>
);

const CustomClearText = () => "Annulla";

const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div className="select-pill text-primary" {...restInnerProps} ref={ref}>
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

ClearIndicator.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

class SelectInput extends React.Component {
  state = {
    selectedOption: null,
    placeholder: "Scegli una opzione",
    isDisabled: false,
    isSearchable: false,
    isMulti: false,
    isClearable: false,
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.onChange(
      selectedOption.value,
      selectedOption.label,
      selectedOption
    );
  };

  render() {
    const { disabled, search, multi, reset, options, label } = this.props;
    const selectedValue =
      this.props.value && this.props.value.length > 0
        ? this.state.selectedOption &&
          this.state.selectedOption.value === this.props.value
          ? this.state.selectedOption
          : undefined ||
            this.props.options.find((o) => o.value === this.props.value)
        : null;

    return (
      <div
        className={classNames(
          "bootstrap-select-wrapper",
          this.props.invalid && "is-invalid"
        )}
        style={{ marginTop: "-4px" }}
      >
        <label
          htmlFor="select"
          style={{ color: this.props.invalid ? "#d9364f" : null }}
        >
          {label}
        </label>
        <Select
          components={{
            MenuList,
            Option,
            SelectContainer,
            DropdownIndicator,
            ClearIndicator,
            GroupHeading,
            IndicatorSeparator: null,
          }}
          id="select"
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          placeholder={this.props.placeholder}
          isDisabled={!!disabled}
          isSearchable={!!search}
          isMulti={!!multi}
          isClearable={!!reset}
          aria-label={this.state.placeholder}
          classNamePrefix={"react-select"}
        />
      </div>
    );
  }
}

export default SelectInput;

SelectInput.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  reset: PropTypes.bool,
  search: PropTypes.bool,
  multi: PropTypes.bool,
  invalid: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  placeholder: PropTypes.string,
};
