import React, { useEffect, useState } from "react";
import { Col, Container, LinkList, Row } from "design-react-kit";
import { useTranslate, useMutation } from "react-admin";
import { get } from "lodash";
import Breadcrumbs from "../../components/Breadcrumbs";
import Loader from "../../components/Loader";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import usePage from "../usePage";
import SearchForm from "./SearchForm";
import SearchResult from "./SearchResult";
import Paginator from "../../components/common/Paginator";
import classNames from "classnames";

const getQuery = () => {
  const location = document.location.href;
  const args = location.split("#/");
  const query = args.length === 2 ? args[1] : "";
  return new URLSearchParams(query);
};

/**
 * @param {URLSearchParams} query
 */
const setQuery = (query) => {
  const args = document.location.href.split("#/");
  const url = args[0];

  document.location.href = `${url}#/?${query}`;
};
const setQueryParam = (name, value) => {
  const query = getQuery();
  query.set(name, value);
  setQuery(query);
};
const getQueryParam = (name, defaultValue = null, array = false) => {
  const query = getQuery();
  const v = query.get(name);
  if (v === null) {
    return defaultValue;
  }
  if (array) {
    return decodeURIComponent(v).split(",");
  }

  return v;
};

const SearchPage = (props) => {
  const [search, setSearch] = useState({
    keyword: getQueryParam("keyword", ""),
    page: getQueryParam("page", 0),
    size: getQueryParam("size", "5"),
    parse_mode: getQueryParam("parse_mode", "direct"),
    full_text_groups: getQueryParam("full_text_groups", [], true),
  });

  const [results, setResults] = useState(null);
  const page = usePage("search", {}, false);
  const translate = useTranslate();
  const currentPage = search.page;
  const keyword = search.keyword;

  const [handleSearch, { loading }] = useMutation(
    {
      type: "post",
      resource: "website",
      payload: {
        method: "searchPage",
        body: { ...search },
      },
    },
    {
      onSuccess: (data) => setResults(get(data, "data")),
      onError: (error) => console.error("error:", error),
    }
  );

  const handleSearchRequest = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (currentPage === 0) {
      const query = getQuery();
      Object.keys(search).forEach((param) => query.set(param, search[param]));
      setQuery(query);
      handleSearch();
    } else {
      setQueryParam("page", 0);

      setSearch({ ...search, page: 0 });
    }
  };

  const handleSearchChange = (name, value) => {
    setSearch({ ...search, [name]: value });
  };
  const handlePageChange = (page) => {
    setQueryParam("page", page);
    setSearch({ ...search, page });
  };

  const handlePageChangeInternal = () => {
    if (keyword.length > 3) {
      handleSearch();
    }
  };

  useEffect(handlePageChangeInternal, [currentPage]);

  if (!page) {
    return <Loader />;
  }

  return (
    <>
      <Breadcrumbs
        {...props}
        items={[
          {
            to: "/",
            label: "website.navbar.home",
          },
          {
            to: "/search",
            label: "website.navbar.search",
          },
        ]}
      />
      <Section className="pt-0">
        <SectionTitle title="website.navbar.search" />
        <Container tag="div" className="px-4">
          <Row className="justify-content-md-center mb-3">
            <Col lg={12} md={12} sm={12} xs={12}>
              <SearchForm
                page={page}
                search={search}
                loading={loading}
                onSearchChange={handleSearchChange}
                onSearchRequest={handleSearchRequest}
              />
            </Col>
          </Row>
        </Container>
      </Section>
      {loading && <Loader />}
      {results !== null && (
        <Section className={classNames("pt-0", loading && "list-search-wait")}>
          <Container tag="div" className="px-4">
            <Col lg={12} md={12} sm={12} xs={12}>
              <h5>
                {translate("pages.search.results.count", {
                  count: get(results, "count"),
                })}
              </h5>
              <hr />
              <LinkList multiline tag="div" className="list-search">
                {get(results, "results", []).map((node, index) => (
                  <SearchResult key={index} node={node} />
                ))}
              </LinkList>
              {get(results, "pages", 0) > 0 && (
                <Paginator
                  pages={get(results, "pages")}
                  page={get(results, "page")}
                  onChange={handlePageChange}
                />
              )}
            </Col>
          </Container>
        </Section>
      )}
    </>
  );
};

export default SearchPage;
