import { Col, Icon, Row } from "design-react-kit";

import PropTypes from "prop-types";
import React from "react";
import TableRowCell from "./TableRowCell";
import { get } from "lodash";

const hasData = (header, cell) => {
  const defType = get(header, "def_type");
  switch (defType) {
    case "one_doc":
      return get(cell, "doc", false) !== false;
    case "many_docs":
      return get(cell, "docs", []).length > 0;
    case "one_link":
      return get(cell, "link", false) !== false;
    case "many_links":
      return get(cell, "links", []).length > 0;
    default:
      return get(cell, "raw_value", null) !== null;
  }
};

const checkIfNegativeAlertHasBeenSet = (row, headers) => {
  const autocompleteAlertHeaders = [];

  // Iterate headers to get all the info about "alert" ds_lists and their "negative alert" options
  headers.map((header) => {
    if(
      get(header, "def_type") == "autocomplete"
      && get(header, "is_alert")
      && get(header, "optionsInfo", []).length > 0
    ) {
      const negativeAlertOptions = get(header, "optionsInfo", []).filter((optionInfo) => {
        return optionInfo.is_negative_alert;
      });

      if(negativeAlertOptions.length > 0) {
        autocompleteAlertHeaders.push({
          header,
          negativeAlertOptions
        });
      }
    }
  });

  // Iterate "alert" ds lists and check, in `row`, if a negative alert has been set
  let negativeAlertHasBeenSet = false;
  autocompleteAlertHeaders.map((alertHeader) => {
    const tableRowCell = get(row, alertHeader.header.id);
    if(tableRowCell) {
      const selectedNegativeOption = alertHeader.negativeAlertOptions.filter(negativeAlertOption => {
        return negativeAlertOption.id === tableRowCell.raw_value
      })

      if(selectedNegativeOption.length > 0) {
        negativeAlertHasBeenSet = true;
      }
    }
  })

  return negativeAlertHasBeenSet;
}

const TableRow = ({ columns, expandable, expanded, toggleRow, row, index }) => {
  const negativeAlertHasBeenSet = checkIfNegativeAlertHasBeenSet(row, (columns || []).concat(expandable));

  return [
    <tr key={index} className={index % 2 === 0 ? "table-row-odd" : ""}>
      {expandable.length > 0 && (
        <td style={{ cursor: "pointer" }}>
          <Icon
            onClick={toggleRow.bind(this, index)}
            icon={expanded.indexOf(index) !== -1 ? "it-collapse" : "it-expand"}
          />
        </td>
      )}
      {columns.map((column) => (
        <td key={get(column, "id")}>
          <TableRowCell
            column={column}
            cell={get(row, `${get(column, "id")}`)}
          />
        </td>
      ))}
    </tr>,
    expanded.indexOf(index) !== -1 ? (
      <tr
        key={`expand-${index}`}
        className={index % 2 === 0 ? "table-row-odd" : ""}
      >
        <td colSpan={columns.length + 1}>
          <Row>
            {expandable
              .filter((column) => hasData(column, get(row, get(column, "id"))))
              .map((expand) => (
                <Col lg={12} md={12} sm={12} xs={12} key={get(expand, "id")}>
                  <TableRowCell
                    expanded
                    column={expand}
                    cell={get(row, `${get(expand, "id")}`)}
                    negativeAlertHasBeenSet={negativeAlertHasBeenSet}
                  />
                </Col>
              ))}
          </Row>
        </td>
      </tr>
    ) : undefined,
  ].filter((tr) => tr !== undefined);
};

const columnPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  def_type: PropTypes.string.isRequired,
});

TableRow.propTypes = {
  expanded: PropTypes.arrayOf(PropTypes.number),
  expandable: PropTypes.arrayOf(columnPropTypes),
  columns: PropTypes.arrayOf(columnPropTypes),
  rows: PropTypes.objectOf(
    PropTypes.shape({
      raw_value: PropTypes.string,
    })
  ),
};

export default TableRow;
