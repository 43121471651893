import { API_URL } from "../../config";
import Input from "./Input";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  img: {
    width: 200,
    marginBottom: theme.spacing(3),
    borderWidth: 1,
    borderColor: "#5c6f82",
    borderStyle: "solid",
  },
}));

const RecaptchaInput = ({ hash, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <img
        className={classes.img}
        src={`${API_URL}/website/resource?_format=json&method=recaptcha&hash=${hash}`}
        alt="Recaptcha"
      />
      <br />
      <Input id="recaptcha" {...props} />
    </>
  );
};

export default RecaptchaInput;
