import { Col, Container, Row } from "design-react-kit";

import PropTypes from "prop-types";
import React from "react";
import ShareThis from "./ShareThis";
import { useTranslate } from "react-admin";

const SectionTitle = ({ sharethis = false, title, args = {} }) => {
  const translate = useTranslate();
  return (
    <Container tag="div">
      <Row tag="div">
        <Col tag="div" className="px-4">
          <h3 className="mb-4">{translate(title, args)}</h3>
        </Col>
        {sharethis && (
          <Col lg={4} className="px-4 sharethis-section-title">
            <ShareThis label={false} />
          </Col>
        )}
      </Row>
    </Container>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionTitle;
