export default (link, enforceHttps = false) => {
  if(link) {
    if(!link.startsWith("https://") && !link.startsWith("http://")) {
      link = `https://${link}`;
    }

    if(enforceHttps && link.startsWith("http://")) {
      link = link.replace(/^http:\/\//, "https://");
    }
  }
  return link;
};
