import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslate, useDataProvider } from "react-admin";
import { Accordion, AccordionBody, AccordionHeader } from "design-react-kit";
import { get } from "lodash";
import Link from "../../components/Link";

const AccordionNode = ({ isActive, onToggle, expanded, node }) => {
  const active = isActive(node);
  const children = active ? get(expanded, `[${node.uuid}]`, []) : [];
  const hasChildren = parseInt(get(node, "children_count", "0"), 10) > 0;
  return [
    <AccordionHeader
      key="header"
      active={active}
      onToggle={hasChildren ? onToggle.bind(this, node) : null}
      tag="div"
    >
      {!active && (
        <Link
          style={{ textDecoration: "none" }}
          href={`/node?uuid=${node.uuid}`}
        >
          {node.name}
        </Link>
      )}
      {active && node.name}
    </AccordionHeader>,
    children.length > 0 && hasChildren && (
      <AccordionBody key="body" active={active}>
        {children.map((child) => (
          <AccordionNode
            key={child.uuid}
            onToggle={onToggle}
            isActive={isActive}
            expanded={expanded}
            node={child}
          />
        ))}
      </AccordionBody>
    ),
  ];
};

AccordionNode.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isActive: PropTypes.func.isRequired,
  expanded: PropTypes.any.isRequired,
  node: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

const LeftNavigator = ({ roots, breadcrumbs, expanded }) => {
  const [expanData, setExpandData] = useState(expanded);
  const [viewAll, setViewAll] = useState(false);
  const [active, setActive] = useState(breadcrumbs.map((bc) => bc.uuid));
  const translate = useTranslate();
  const isActive = (node) => active.indexOf(get(node, "uuid")) !== -1;
  const dataProvider = useDataProvider();
  const handleToggle = (node) => {
    const isActive = active.indexOf(node.uuid) === -1;
    setActive(
      !isActive
        ? active.filter((uuid) => uuid !== node.uuid)
        : active.concat([node.uuid])
    );
    if (isActive && !expanData[node.uuid]) {
      dataProvider
        .get("website", {
          method: "nodeChildren",
          uuid: node.uuid,
        })
        .then(({ data: { children } }) =>
          setExpandData({ ...expanData, [node.uuid]: children })
        );
    }
  };
  const handleViewAll = () => setViewAll(!viewAll);

  const nearest = 2;
  const targetRoot = get(breadcrumbs, "[0]", null);
  const indexOf = roots.map((r) => r.uuid).indexOf(get(targetRoot, "uuid"));
  const start = indexOf > nearest ? indexOf - nearest : indexOf;
  const end =
    indexOf < roots.length - nearest ? indexOf + nearest : roots.length;
  return (
    <Accordion>
      {start > 0 && (
        <AccordionHeader active={viewAll} onToggle={handleViewAll}>
          {translate(`pages.node.tree.view.${viewAll ? "hide" : "show"}`)}
        </AccordionHeader>
      )}

      {roots
        .filter((_, i) => viewAll || (i >= start && i <= end))
        .map((root) => (
          <AccordionNode
            key={root.uuid}
            expanded={expanData}
            onToggle={handleToggle}
            isActive={isActive}
            node={root}
          />
        ))}
      {end < roots.length && (
        <AccordionHeader active={viewAll} onToggle={handleViewAll}>
          {translate(`pages.node.tree.view.${viewAll ? "hide" : "show"}`)}
        </AccordionHeader>
      )}
    </Accordion>
  );
};

export default LeftNavigator;
