import { useQueryWithStore } from "react-admin";

const usePage = (name, params = {}, defaults = {}, setPageLoadOnce = false) => {
  const { data, loaded } = useQueryWithStore({
    type: "get",
    resource: "website",
    payload: {
      method: `${name}Page`,
      ...params,
    },
  });
  loaded && setPageLoadOnce && setPageLoadOnce(true)
  return loaded ? data : defaults;
};
export default usePage;
