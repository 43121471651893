import { Icon, LinkListItem } from "design-react-kit";
import React, { useMemo } from "react";

import SearchTableRows from "./SearchTableRows";
import classNames from "classnames";
import { get } from "lodash";
import moment from "moment";
import { useTranslate } from "react-admin";

const SearchResult = ({ node }) => {
  const translate = useTranslate();
  const dateFormat = translate("website.date.format.short");
  const { published, modified } = useMemo(() => {
    let published = get(node, "published", null);
    if (published) {
      published = moment(published).format(dateFormat);
    }
    let modified = get(node, "modified", null);
    if (modified) {
      modified = moment(modified).format(dateFormat);
    }
    return {
      published,
      modified,
    };
  }, [node, dateFormat]);

  return (
    <LinkListItem
      key={get(node, "uuid")}
      tag="a"
      className={classNames("right-icon list-item")}
      href={`/node?uuid=${get(node, "uuid")}`}
    >
      <h4>{get(node, "tree").substr(1).split("/").join(" / ")}</h4>
      <i className="it-chevron-right right" />
      <Icon
        className="icon-right"
        color="primary"
        icon="it-chevron-right"
        padding={false}
        size=""
        style={{
          ariaHidden: true,
        }}
      />
      <div>
        <div
          className="p"
          dangerouslySetInnerHTML={{ __html: get(node, "description") }}
        />
        <p className="mt-2">
          <small>
            {get(node, "law_references").map((lr, i) => (
              <a
                rel="noopener noreferrer"
                target="_blank"
                key={i}
                href={lr.href}
              >
                {lr.label}
              </a>
            ))}
          </small>
        </p>
      </div>
      <p>
        <strong>
          {published !== null &&
            translate("components.node_link_list_item.published", {
              date: published,
            })}
          {published !== null && modified !== null && " | "}
          {modified !== null &&
            translate("components.node_link_list_item.modified", {
              date: modified,
            })}
        </strong>
        <br />
        {get(node, "tags")
          .map((tag) => (
            <a href={`/tag/${tag}`}>{tag.startsWith("#") ? tag : `#${tag}`}</a>
          ))
          .join(", ")}
      </p>
      {get(node, "table", null) !== null && (
        <SearchTableRows data={[node]} showAsResult />
      )}
    </LinkListItem>
  );
};

export default SearchResult;
