import React from "react";
import { Icon } from "design-react-kit";
import { useTranslate } from "react-admin";
import classNames from "classnames";

const Paginator = ({ pages, page, maxItems = 6, onChange }) => {
  const translate = useTranslate();
  const buttons = Array.from(Array(pages))
    .map((_, page) => page)
    .filter((p) => p <= page + maxItems);

  if (pages === 1) {
    return null;
  }

  return (
    <nav className="pagination-wrapper justify-content-end">
      <ul className="pagination">
        <li className={classNames("page-item", page === 0 && "disabled")}>
          <a
            className="page-link"
            href={`#/`}
            onClick={() => onChange(page - 1)}
          >
            <Icon icon="it-chevron-left" />

            <span className="sr-only">
              {translate("pages.node.table.prev_page")}
            </span>
          </a>
        </li>
        {buttons.map((p) => {
          const active = p === page;
          const className = classNames(
            "page-item",
            active && "page-disabled active"
          );
          const props = {};
          if (active) {
            props["aria-current"] = "page";
          }
          return (
            <li className={className} key={p}>
              <a
                className="page-link"
                href={`#/?p=${p}`}
                onClick={(e) =>
                  !e.preventDefault() && !e.stopPropagation() && onChange(p)
                }
                {...props}
              >
                {p + 1}
              </a>
            </li>
          );
        })}

        <li
          className={classNames("page-item", page === pages - 1 && "disabled")}
        >
          <a
            className="page-link"
            href={`#/`}
            onClick={() => onChange(page + 1)}
          >
            <span className="sr-only">
              {translate("pages.node.table.next_page")}
            </span>
            <Icon icon="it-chevron-right" />
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default Paginator;
