import { Col, Container, Row } from "design-react-kit";
import React, { useMemo, useState, useEffect, createContext} from "react";

import Breadcrumbs from "../../components/Breadcrumbs";
import EmptyMessage from "./EmptyMessage";
import LeftNavigator from "./LeftNavigator";
import Loader from "../../components/Loader";
import Nodes from "./Nodes";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import SimpleBlocks from "./content-types/SimpleBlocks";
import TableRows from "./content-types/TableRows";
import Tree from "./content-types/Tree";
import { get } from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import usePage from "../usePage";
import { useTranslate } from "react-admin";

export const DepartmentContext = createContext();
const NodePage = (props) => {
  const translate = useTranslate();
  const dateFormat = useMemo(
    () => translate("website.date.format.short"),
    [translate]
  );
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const currentLocation = document.location.toString();
  const queryParams = currentLocation.split("?");
  const qs = new URLSearchParams(queryParams[1].replace('#/', ''));
  const contentTreePath = qs.get("contentTreePath") || null;
  const pageSize = 10;
  const [pageNumber, setPageNumber] = useState(JSON.parse(qs.get("p") || "0"));
  const [filterValue, setFilterValue] = useState("");
  const [sortValue, setSortValue] = useState({sort: "", direction: null});
  const [changes, setChanges] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [pageLoadOnce, setPageLoadOnce] = useState(false);
  const [assessoratoValue, setAssessoratoValue] = React.useState("");
  const [dipartimentValue, setDipartimentValue] = React.useState([]);

  const page = usePage(
    "node",
    {
      uuid: params.get("uuid"),
      filter: {
        limit: pageSize,
        filterValue,
        contentTreePath
      },
      sortValue,
      page: pageNumber
    },
    false,
    !pageLoadOnce && setPageLoadOnce
  );

  const children = useMemo(() => get(page, "children", []), [page]);
  const contents = useMemo(() => get(page, "contents", []), [page]);
  const node = useMemo(() => get(page, "node", []), [page]);
  const contentType = useMemo(() => get(node, "content_type", "node"), [node]);
  const allowedContentType = useMemo(() => get(page, "node.allowed_content_type", []), [node]);
  const roots = useMemo(() => get(page, "roots", []), [page]);
  const pageBreadcrumbs = useMemo(() => get(page, "breadcrumbs", []), [node]);
  const pageExpanded = useMemo(() => get(page, "expanded", []), [node]);

  if (page === false) {
    return <Loader />;
  }
  
  const breadcrumbs = [{ to: "/", label: "website.navbar.home" }].concat(
    get(page, "breadcrumbs", []).map((item) => ({
      to: `/node?uuid=${get(item, "uuid")}`,
      label: get(item, "name"),
    }))
  );

  if (!get(page, "success", true)) {
    return (
      <>
        <Breadcrumbs {...props} items={breadcrumbs} />
        <Section className="pt-0">
          <SectionTitle title={get(page, "node.name", "website.navbar.node")} />
          <Container tag="div">
            <Row className="justify-content-center">
              <Col lg={8}>
                <br />
                <EmptyMessage uuid={params.get("uuid")} />
              </Col>
            </Row>
          </Container>
        </Section>
      </>
    );
  }


  return (
    <>
      <Breadcrumbs {...props} items={breadcrumbs} />
      <Section className="pt-0">
        <SectionTitle title={get(page, "node.name", "website.navbar.node")} />
        <Container tag="div">
          <Row>
            <Col lg={3}>
              <LeftNavigator
                roots={roots}
                breadcrumbs={pageBreadcrumbs}
                expanded={pageExpanded}
              />
            </Col>
            <Col lg={9}>
              {allowedContentType !== "node" && (
                <Container>
                  {contentType !== "simple_block" && (
                    <Row tag="div" className="px-3">
                      <Col lg={12}>
                        <div
                          className="lead text-justify"
                          dangerouslySetInnerHTML={{
                            __html: get(node, "description"),
                          }}
                        />
                        <small>
                          {get(node, "law_references").map((lr, i) => (
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              style={{
                                textDecoration: "underline",
                                marginRight: 8,
                              }}
                              key={i}
                              href={
                                get(lr, "href", null) !== null
                                  ? get(lr, "href")
                                  : "#"
                              }
                            >
                              {lr.label}
                            </a>
                          ))}
                        </small>
                        {get(node, "redirect_url", null) !== null && (
                          <h4 className="redirect-url">
                            <a
                              href={get(node, "redirect_url")}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {get(node, "redirect_title")} &rarr;
                            </a>
                          </h4>
                        )}
                      </Col>
                    </Row>
                  )}
                  {(allowedContentType === "simple_block" ||
                    contentType === "simple_block") && (
                    <SimpleBlocks data={contents} />
                  )}
                  {["simple_table", "filterable_table"].indexOf(
                    allowedContentType
                  ) !== -1 && 
                  <DepartmentContext.Provider value={
                    { assessoratoState: 
                      {setAssessoratoValue, assessoratoValue}, 
                    dipartimentoState: 
                      {setDipartimentValue, dipartimentValue}
                    }
                  }>
                    <TableRows 
                      data={contents} 
                      setFilterValue={setFilterValue}
                      setSortValue={setSortValue}
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      sortValue={sortValue}
                      filterValue={filterValue}
                      changes={changes}
                      setChanges={setChanges}
                      showFilters={showFilters}
                      setShowFilters={setShowFilters}
                      pageData={page}
                    />
                  </DepartmentContext.Provider>
                  }
                  {allowedContentType === "tree" && <Tree data={contents} />}
                </Container>
              )}
              {allowedContentType !== "tree" && (
                <Nodes uuid={params.get("uuid")} items={children} />
              )}
              {/* {contentType === "node" &&
                contents.length === 0 &&
                children.length === 0 && (
                  <EmptyMessage node={node} className="px-4 pt-4" />
                )} */}
              {(node.published !== null || node.modified !== null) && (
                <Container>
                  <Row tag="div" className="px-3 pt-3">
                    <Col>
                      {node.published !== null &&
                        translate("pages.node.published", {
                          date: moment(get(node, "published")).format(
                            dateFormat
                          ),
                        })}
                      {node.modified !== null &&
                        node.published !== null &&
                        " | "}
                      {node.modified !== null &&
                        translate("pages.node.modified", {
                          date: moment(get(node, "modified")).format(
                            dateFormat
                          ),
                        })}
                      <br />
                    </Col>
                  </Row>
                </Container>
              )}
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default NodePage;
