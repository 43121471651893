import React, { Fragment } from "react";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import { useTranslate } from "react-admin";
import { get } from "lodash";
import Link from "../../components/Link";

const PolicyField = ({ formData, formStatus, onChange }) => {
  const translate = useTranslate();
  return (
    <Fragment>
      <CheckboxInput
        id="is_policy_accepted"
        value={formData.is_policy_accepted}
        invalid={get(formStatus, "errors.is_policy_accepted", false)}
        label={translate("pages.contact.form.is_policy_accepted")}
        onChange={(checked) => onChange("is_policy_accepted", checked)}
      />
      <Link href="/privacy-policy">
        {translate("pages.contact.form.privacy_policy_link")}
      </Link>
    </Fragment>
  );
};
export default PolicyField;
