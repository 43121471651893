const { BASE_URL } = require("./config");
const clearStorage = () => {
  localStorage.removeItem("csrf_token");
  localStorage.removeItem("logout_token");
  localStorage.removeItem("roles");
  localStorage.removeItem("name");
  localStorage.removeItem("uid");
};
const clearCookies = () => {
  document.cookie = "";
};
/**
 * @see https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/what-jsonapi-doesnt-do
 */
const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(
      `${BASE_URL}/user/login?_format=json&include=roles`,
      {
        method: "POST",
        body: JSON.stringify({
          name: username,
          pass: password,
        }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    );

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(
        ({ csrf_token, logout_token, current_user: { roles, name, uid } }) => {
          localStorage.setItem("csrf_token", csrf_token);
          localStorage.setItem("logout_token", logout_token);
          localStorage.setItem("roles", JSON.stringify(roles || "[]"));
          localStorage.setItem("name", name);
          localStorage.setItem("uid", uid);
          localStorage.setItem("username", username);
          localStorage.setItem("password", password);
        }
      );
  },
  logout: () => {
    const logoutToken = localStorage.getItem("logout_token");
    const csrfToken = localStorage.getItem("csrf_token");

    clearStorage();
    clearCookies();

    return fetch(`${BASE_URL}/user/logout?_format=json&token=${logoutToken}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }).then(() => Promise.resolve());
  },
  checkAuth: (params) =>
    localStorage.getItem("csrf_token") ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      clearStorage();
      clearCookies();
      return Promise.reject(error);
    }

    return Promise.resolve();
  },
  getPermissions: (params) => {
    const roles = JSON.parse(localStorage.getItem("roles") || "[]");
    if (roles.length === 0) {
      return Promise.reject();
    }
    return Promise.resolve((role) => roles.indexOf(role) !== -1);
  },
};

export default authProvider;
