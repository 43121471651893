import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Icon } from "design-react-kit";
const TableHeader = ({ column, sort, direction, onSort }) => {
  const defType = get(column, "def_type");
  if (["doc", "docs", "link", "links"].indexOf(defType) !== -1) {
    return get(column, "name");
  }
  const handleSort = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const dir =
      sort === get(column, "id") && direction === "DESC" ? "ASC" : "DESC";
    onSort(get(column, "id"), dir);
  };
  return (
    <th key={get(column, "id")}>
      <a href={`#/sort=${get(column, "id")}`} onClick={handleSort}>
        {get(column, "name")}
        {sort === get(column, "id") && (
          <Icon
            size="small"
            className="table-sort-icon"
            icon={direction === "DESC" ? "it-collapse" : "it-expand"}
          />
        )}
      </a>
    </th>
  );
};

TableHeader.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    def_type: PropTypes.string.isRequired,
  }),
  sort: PropTypes.string,
  direction: PropTypes.string,
  onSort: PropTypes.func.isRequired,
};

export default TableHeader;
