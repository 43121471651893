import React, { useState, useEffect, useContext } from "react";

import AsyncSelect from "react-select/async";
import { Icon } from "design-react-kit";
import { components } from "react-select";
import makeid from "../../utils/makeid";
import { DepartmentContext } from "../../pages/node/NodePage"
import { useTranslate } from "react-admin";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span style={{ padding: "0px 5px" }} aria-hidden="true">
        <Icon icon="it-search" style={{ ariaHidden: true }} size="sm" />
      </span>
    </components.DropdownIndicator>
  );
};

const Input = (props) => {
  // eslint-disable-next-line react/prop-types
  if (props.isHidden) {
    return <components.Input {...props} />;
  }
  return (
    <div style={{ border: `1px dotted blue}` }}>
      <components.Input {...props} />
    </div>
  );
};

const AutocompleteInput = ({ label, options, onChange, fValue, type, filter, ...props }) => {
  // "value" is used to show or propagate it externally
  // eslint-disable-next-line no-unused-vars
  const translate = useTranslate();
  const [value, setValue] = useState(props.value);
  const id = makeid(10);
  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setValue(inputValue);
    return inputValue;
  };

  const handleChange = (option) => {
    onChange(option ? option.value : null);
    setValue(option);
  };

  return (
    <div className="form-group">
      <label htmlFor={id} className="active autocomplete">
        {label}
      </label>
      <AsyncSelect
        components={{
          DropdownIndicator,
          Input,
          IndicatorSeparator: null,
        }}
        cacheOptions
        loadOptions={(inputValue, callback) => {
          setTimeout(() => {
            callback(
              options.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
              )
            );
          }, 1000);
        }}
        defaultOptions
        value={props?.value === null || props?.value?.length === 0 ? null : value}
        placeholder={label}
        onInputChange={handleInputChange}
        onChange={handleChange}
        classNamePrefix="react-autocomplete"
        noOptionsMessage={() => <p>{translate("pages.department.filter.no_result")}</p>}
      />
    </div>
  );
};

export default AutocompleteInput;
