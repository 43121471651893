import { stringify } from "qs";
import { get } from "lodash";

import subrequests, {
  getHeaders,
  getHeadersForCustomResource,
} from "./subrequests";

const mapRelationship = (relationship, included) => {
  if (relationship === null) {
    return null;
  }
  if (relationship.data === null) {
    return null;
  }
  if (Array.isArray(relationship.data)) {
    let data = relationship.data.map((item) => {
      const { attributes, relationships } = included.find(
        (include) => include.type === item.type && include.id === item.id
      ) || { attributes: {}, relationships: {} };

      return {
        ...item,
        ...attributes,
        relationships: mapRelationships(relationships, included),
      };
    });
    const sort = get(data, "[0].order_index", false);
    if (sort !== false) {
      data = data.sort((a, b) => a.order_index - b.order_index);
    }

    return data;
  } else if (typeof relationship.data === "object") {
    const { attributes, relationships } = included.find(
      (include) =>
        include.type === relationship.data.type &&
        include.id === relationship.data.id
    ) || { attributes: {}, relationships: {} };
    const remappedRelationship = {
      ...relationship.data,
      ...attributes,
      relationships: mapRelationships(relationships, included),
    };
    return remappedRelationship;
  }
};
const mapRelationships = (relationships, included) =>
  relationships
    ? Object.keys(relationships).reduce(
        (remapped, relationshipName) => ({
          ...remapped,
          [relationshipName]: {
            ...relationships[relationshipName],
            data: mapRelationship(relationships[relationshipName], included),
          },
        }),
        {}
      )
    : {};

const mapList = ({ data, included, meta: { count } }) => {
  const finalData = {
    data: data.map((item) =>
      get(mapOne({ data: item, included, mode: "list" }), "data")
    ),
    total: parseInt(count),
  };
  return finalData;
};

const mapOne = ({ data, included }) => {
  const finalData = {
    data: {
      id: data.id,
      ...data.attributes,
      relationships:
        data.relationships && included
          ? mapRelationships(data.relationships, included)
          : data.relationships,
    },
  };
  return finalData;
};

const createEntity = (item, settings = {}) => {
  const apiUrl = get(settings, "apiUrl");
  const entityType = get(item, "type", null);
  if (entityType === null) {
    return Promise.reject("Invalid item type for: " + JSON.stringify(item));
  }
  const attributes = { ...item };
  delete attributes.id;
  delete attributes.status;
  delete attributes.changed;
  delete attributes.created;
  delete attributes.default_langcode;
  delete attributes.drupal_internal__id;
  delete attributes.drupal_internal__vid;
  delete attributes.langcode;
  delete attributes.relationships;
  delete attributes.revision_created;
  delete attributes.revision_log_message;
  delete attributes.revision_translation_affected;

  return fetch(`${apiUrl}/${get(item, "type")}`, {
    method: "POST",
    headers: getHeaders(settings),
    body: JSON.stringify({
      data: {
        type: get(item, "type"),
        attributes,
        relationships: get(item, "relationships", {}),
      },
    }),
  }).then((response) => {
    const { status } = response;
    if (status >= 200 && status < 300) {
      return response.json().then(({ data }) => ({
        id: data.id,
        type: get(item, "type"),
      }));
    } else {
      return Promise.resolve({
        id: null,
        type: get(item, "type"),
      });
    }
  });
};
const updateEntity = (item, settings = {}) => {
  const entityType = get(item, "type", null);
  const apiUrl = get(settings, "apiUrl");
  if (entityType === null) {
    return Promise.reject(`Invalid Entity Type`);
  }
  return fetch(`${apiUrl}/${get(item, "type")}/${get(item, "id")}`, {
    method: "PATCH",
    headers: getHeaders(settings),
    body: JSON.stringify({
      data: {
        type: get(item, "type"),
        id: item.id,
        attributes: { ...item, relationships: undefined, type: undefined },
        relationships: get(item, "relationships"),
      },
    }),
  }).then((response) => {
    const { status } = response;
    if (status >= 200 && status < 300) {
      return Promise.resolve({
        id: item.id,
        type: get(item, "type"),
      });
    } else {
      return Promise.reject({
        id: null,
        type: get(item, "type"),
      });
    }
  });
};

/**
 * Upload new file in to Drupal.
 * The upload feature requires RestUI plugin enabled in to the installation.
 *
 * @param {string} referenceEntity The referenced entity for which you are creating the file.
 * @param {string} type Type associated to the file to upload, necessary to create the final URL where file will be uploaded.
 * @param {File} rawFile The rawFile object to upload.
 */
const uploadFile = async (referenceEntity, type, rawFile, settings) => {
  const csrfToken = localStorage.getItem("csrf_token");
  const apiUrl = get(settings, "apiUrl");
  const baseEntityType = get(
    settings,
    `map.${referenceEntity}`,
    referenceEntity
  );
  const url = `${apiUrl}/${baseEntityType}/${type}`;
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");
  const headers = new Headers({
    "Content-Type": "application/octet-stream",
    "Content-Disposition": `filename="${rawFile.name}"`,
    "X-CSRF-Token": csrfToken,
    Authorization: `Basic ${btoa(username + ":" + password)}`,
  });
  const formData = new FormData();
  formData.append("file", rawFile);
  const {
    data: { id },
  } = await fetch(url, {
    method: "POST",
    body: formData,
    headers: headers,
  }).then((response) => response.json());
  return Promise.resolve(id);
};

const isRelationshipReference = (item) => {
  const type = get(item, "type", null);
  const relationships = get(item, "relationships", null);
  const id = get(item, "id", null);
  return (
    type !== null &&
    id !== null &&
    Object.keys(item).length === (relationships !== null ? 3 : 2)
  );
};
const prepareDataForEntity = async (
  entity,
  relationshipName,
  settings,
  referenceEntity
) => {
  const file = get(entity, "rawFile", null);
  const type = get(entity, "type", null);
  if (type === "file--file" || (file !== null && file instanceof File)) {
    if (get(entity, "id", null) !== null) {
      // File already exists, nothing to do.
      return Promise.resolve({
        id: entity.id,
        type: entity.type,
      });
    }
    const id = await uploadFile(
      referenceEntity,
      relationshipName,
      file,
      settings
    );
    return Promise.resolve({ id, type: "file--file" });
  } else if (get(entity, "id", null) === null) {
    return await createEntity(entity, settings);
  } else {
    return await updateEntity(entity, settings);
  }
};
const prepareDataForRelationships = async (
  data,
  settings,
  referenceEntity = ""
) => {
  const relationships = get(data, "relationships", null);
  if (relationships === null) {
    // Good, nothing more to do with this item.
    return Promise.resolve({});
  }
  return Object.keys(relationships).reduce(
    async (reducedData, relationshipName) => {
      let relationshipData = get(
        relationships,
        `${relationshipName}.data`,
        null
      );
      const type = get(data, "type");
      const saveableList = get(settings, `saveable.${type}`, []);
      const saveable = saveableList.indexOf(relationshipName) !== -1;
      if (saveable && Array.isArray(relationshipData)) {
        for (var i = 0; i < relationshipData.length; i++) {
          let arrayItem = relationshipData[i];
          if (isRelationshipReference(arrayItem)) {
            continue;
          }

          let type = get(arrayItem, "type", null);
          if (type === null) {
            type = get(settings, `map.${relationshipName}`, null);
            if (type === null) {
              return Promise.reject(
                `Invalid item type for: ${relationshipName}`
              );
            }
            arrayItem.type = type;
          }

          arrayItem.relationships = await prepareDataForRelationships(
            arrayItem,
            settings,
            relationshipName
          );

          relationshipData[i] = await prepareDataForEntity(
            arrayItem,
            relationshipName,
            settings,
            referenceEntity
          );
        }
      } else if (saveable && relationshipData !== null) {
        if (!isRelationshipReference(relationshipData)) {
          let type = get(relationshipData, "type", null);
          if (type === null) {
            type = get(settings, `map.${relationshipName}`, null);
            relationshipData.type = type;
          }
          relationshipData.relationships = await prepareDataForRelationships(
            relationshipData,
            settings,
            relationshipName
          );
          relationshipData = await prepareDataForEntity(
            relationshipData,
            relationshipName,
            settings,
            referenceEntity
          );
        }
      }
      let newData = {
        ...(await reducedData),
        [relationshipName]: {
          ...relationships[relationshipName],
          data: relationshipData,
        },
      };
      return Promise.resolve(newData);
    },
    Promise.resolve({})
  );
};

const prepareData = async (data, settings) => {
  return {
    ...data,
    relationships: await prepareDataForRelationships(data, settings),
  };
};

const dataProvider = (apiUrl, settings = {}) => ({
  get: (resource, params) => {
    const url = `${apiUrl}/${resource}/resource?_format=json&${stringify(
      params
    )}&ts=${new Date().getTime()}`;
    return fetch(url, {
      method: "GET",
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  },
  post: (resource, params) => {
    const { method, body } = params;
    const url = `${apiUrl}/${resource}/resource?_format=json&method=${method}`;
    return fetch(url, {
      method: "POST",
      headers: getHeaders({
        headers: {
          "Content-Type": "application/json",
        },
      }),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  },

  // Tree Node

  getTreeRoots: (resource) => {
    const timestamp = new Date().getTime();
    const url = `${apiUrl}/${resource}/resource?_format=json&ts=${timestamp}&method=roots`;
    return fetch(url, {
      method: "GET",
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then(({ roots, message }) =>
        !roots ? Promise.reject(message) : Promise.resolve({ data: { roots } })
      )
      .catch((error) => Promise.reject(error));
  },
  getTreeChildren: (resource, id) => {
    const timestamp = new Date().getTime();
    const url = `${apiUrl}/${resource}/resource?_format=json&ts=${timestamp}&method=children&id=${id}`;
    return fetch(url, {
      method: "GET",
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then(({ children }) => ({
        data: { children },
      }));
  },

  getTreePathinfo: (resource, { id }) => {
    const timestamp = new Date().getTime();
    const url = `${apiUrl}/${resource}/resource?_format=json&ts=${timestamp}&method=pathinfo&id=${id}`;
    return fetch(url, {
      method: "GET",
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  },

  postTreeMove: (resource, id, destinationId) => {
    const url = `${apiUrl}/${resource}/resource?_format=json&method=move`;
    return fetch(url, {
      method: "POST",
      headers: getHeadersForCustomResource(),
      body: JSON.stringify({
        id,
        destination_id: destinationId /** Respect drupal notation. */,
      }),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  },

  solveHierarchy: (resource) => {
    const url = `${apiUrl}/${resource}/resource?_format=json&method=solveHierarchy`;
    return fetch(url, {
      method: "GET",
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  },

  getList: (resource, params) => {
    const { filter, pagination, sort } = params;
    const { perPage, page } = pagination;
    const { field, order } = sort || { field: "id", order: "DESC" };
    const prefix = order === "ASC" ? "" : "-";
    let query = {
      "page[offset]": (page - 1) * perPage,
      "page[limit]": perPage,
      sort: `${prefix}${field}`,
      include: get(settings, `include.${resource}`, []).join(","),
    };

    const customFilterBuilder = get(settings, `filter.${resource}`, null);
    if (customFilterBuilder !== null) {
      query = customFilterBuilder(query, filter, params);
    } else {
      Object.keys(filter || {}).forEach((key) => {
        query[`filter[${key}]`] = filter[key];
      });
    }

    let suffix = "";
    if (settings.custom[resource]) {
      suffix = "&_format=json";
    }
    const alias = get(settings, `alias.${resource}`, resource);
    const url = `${apiUrl}/${alias}?${stringify(query)}${suffix}`;

    return fetch(url, {
      method: "GET",
      headers: getHeaders(
        settings,
        get(settings, `custom.${resource}.auth`, true)
      ),
    })
      .then((response) => response.json())
      .then(mapList);
  },
  getOne: (resource, params) => {
    const { id } = params;
    const include = get(settings, `include.${resource}`, []).join(",");
    const url = `${apiUrl}/${resource}/${id}?include=${include}`;
    return fetch(url, {
      method: "GET",
      headers: getHeaders(settings),
    })
      .then((response) => response.json())
      .then(mapOne);
  },
  getMany: (resource, params) => {
    let query = stringify({
      "filter[id][condition][operator]": "IN",
      "filter[id][condition][path]": "id",
    });
    params.ids.forEach(
      (id) => (query = `${query}&filter[id][condition][value][]=${id}`)
    );

    const url = `${apiUrl}/${resource}?${query}`;
    return fetch(url, {
      method: "GET",
      headers: getHeaders(settings),
    })
      .then((response) => response.json())
      .then(({ data, meta: { count } }) => ({
        data: data.map((item) => ({
          id: item.id,
          ...item.attributes,
          relationships: item.relationships,
        })),
        total: parseInt(count, 10),
      }));
  },
  getManyReference: (resource, params) => {
    const { id, filter, pagination, target } = params;
    const { page, perPage } = pagination;
    const query = {
      "page[number]": page,
      "page[limit]": perPage,
    };

    Object.keys(filter || {}).forEach(
      (key) => (query[`filter[${key}]`] = filter[key])
    );
    if (target && id) {
      query[`filter[${params.target}]`] = id;
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return fetch(url, {})
      .then((response) => response.json())
      .then(({ data, meta: { count } }) => ({
        data: data.map((item) => ({
          id: item.id,
          ...item.attributes,
          relationships: item.relationships,
        })),
        count: parseInt(count, 10),
      }));
  },
  create: (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    const attributes = params.data;
    const relationships = attributes.relationships;
    delete attributes.relationships;
    const data = {
      type: resource,
      attributes,
      relationships,
    };

    if (get(settings, "subrequests", []).indexOf(resource) !== -1) {
      let url = apiUrl.replace("/api", "");
      return subrequests(data, settings).then((requests) =>
        fetch(`${url}/subrequests?_format=json`, {
          method: "POST",
          headers: getHeaders(),
          body: JSON.stringify(requests),
        })
          .then((response) => response.json())
          .then((response) => ({
            data: get(
              JSON.parse(
                get(response, "last.body", get(response, "last#body{0}.body"))
              ),
              "data"
            ),
          }))
      );
    }

    return prepareData(data, settings).then((data) =>
      fetch(url, {
        method: "POST",
        headers: getHeaders(settings),
        body: JSON.stringify({
          data,
        }),
      })
        .then((response) => response.json())
        .then(({ data: { id, attributes, relationships } }) => ({
          data: {
            id,
            ...attributes,
            relationships,
          },
        }))
    );
  },
  update: (resource, params) => {
    const { id } = params;
    const url = `${apiUrl}/${resource}/${params.id}`;
    const attributes = params.data;
    const relationships = attributes.relationships;
    delete attributes.id;
    delete attributes.relationships;

    const data = {
      id,
      type: resource,
      attributes,
      relationships,
    };

    if (get(settings, "subrequests", []).indexOf(resource) !== -1) {
      let url = apiUrl.replace("/api", "");
      return subrequests(data, settings).then((requests) =>
        fetch(`${url}/subrequests?_format=json`, {
          method: "POST",
          headers: getHeadersForCustomResource(settings),
          body: JSON.stringify(requests),
        })
          .then((response) => response.json())
          .then((response) => ({
            data: get(
              JSON.parse(
                get(response, "last.body", get(response, "last#body{0}.body"))
              ),
              "data"
            ),
          }))
      );
    }

    return prepareData(data, settings).then((data) =>
      fetch(url, {
        method: "PATCH",
        headers: getHeaders(settings),
        body: JSON.stringify({
          data,
        }),
      })
        .then((response) => response.json())
        .then(({ data: { id, attributes, relationships } }) => ({
          data: {
            id,
            ...attributes,
            relationships,
          },
        }))
    );
  },
  updateMany: (resource, params) => Promise,
  delete: (resource, params) => {
    const { id } = params;
    const url = `${apiUrl}/${resource}/${id}`;
    return fetch(url, {
      method: "DELETE",
      headers: getHeaders(settings),
    }).then((response) => {
      if (response.status < 200 || response.status > 300) {
        return Promise.reject(response.statusText);
      }
      return Promise.resolve({ error: false, data: { id } });
    });
  },
  deleteMany: (resource, params) => {
    const { ids } = params;
    return Promise.all(
      ids.map((id) =>
        fetch(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
          headers: getHeaders(settings),
        }).then(({ status, statusText }) => ({
          error: status < 200 || status > 300,
          message: statusText,
        }))
      )
    ).then((responses) => {
      let errors = responses.filter((r) => r.error);
      if (errors.length > 0) {
        return Promise.reject(errors.map((e) => e.message).join("\n"));
      }

      return {
        data: { ids },
      };
    });
  },
});
export default dataProvider;
