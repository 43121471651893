import { Button, Col, Icon, Row } from "design-react-kit";
import React, { useState } from "react";

import { BASE_URL } from "../../config";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import Input from "../../components/inputs/Input";
import { get } from "lodash";
import moment from "moment";
import { stringify } from "qs";
import { useTranslate } from "react-admin";

const CUSTOM_FIELDS_MAP = {
  basic: {
    birth_city: { type: "text" },
    birth_date: { type: "date" },
    residence_city: { type: "text" },
    residence_address: { type: "text" },
    phone: { type: "text" },
  },
  legal: {
    legal_name: { type: "text" },
    legal_address: { type: "text" },
    legal_email: { type: "email" },
    legal_phone: { type: "tel" },
  },
  opts: {
    flag_opt_1: { type: "checkbox" },
    msg_opt_1: { type: "optional_text", reference: "flag_opt_1" },

    flag_opt_2: { type: "checkbox" },
    msg_opt_2: { type: "optional_text", reference: "flag_opt_2" },

    flag_opt_3: { type: "checkbox", visible_for: ["general"] },
    msg_opt_3: {
      type: "optional_text",
      reference: "flag_opt_3",
      visible_for: ["general"],
    },

    flag_opt_4: { type: "checkbox", visible_for: ["civic"] },
    msg_opt_4: {
      type: "optional_text",
      reference: "flag_opt_4",
      visible_for: ["civic"],
    },
    msg_opt_5: {
      type: "radio",
      visible_for: ["general"],
      options: ["a", "b", "c"],
    },
    msg_opt_6: {
      visible_for: ["general"],
      type: "optional_text",
      reference: "msg_opt_5",
      sub_reference: ["b", "c"],
    },
  },
  docs: {
    doc_identity: {
      type: "file",
      accept: [".pdf", ".jpg", ".jpeg", ".png"],
      maxSize: 10,
    },
    doc_signed: {
      type: "file",
      accept: [".pdf", ".jpg", ".jpeg", ".png"],
      maxSize: 10,
    },
  },
};
const AdditionalFields = ({
  onChange,
  formData,
  formStatus,
  handleValidation,
}) => {
  const [expanded, setExpanded] = useState({});
  const translate = useTranslate();
  const contactType = get(formData, "contact_type", "none");

  const handlePrint = (e) => {
    const { hasErrors } = handleValidation(["doc_identity", "doc_signed"]);
    if (hasErrors) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const sections = Object.keys(CUSTOM_FIELDS_MAP).map((key) => ({
    expandable: key === "legal",
    key,
    title:
      key === "basic"
        ? false
        : key === "opts"
        ? `pages.contact.form.${contactType}.${key}_section`
        : `pages.contact.form.${key}_section`,
    fields: Object.keys(CUSTOM_FIELDS_MAP[key])
      .map((fieldKey) => ({
        ...CUSTOM_FIELDS_MAP[key][fieldKey],
        name: fieldKey,
      }))
      .filter(
        (field) =>
          field.visible_for === undefined ||
          field.visible_for.indexOf(contactType) !== -1
      ),
  }));
  return sections.map((section) => (
    <Row key={section.title}>
      {(section.expandable || section.title) && (
        <Col
          lg={12}
          style={{
            marginBottom: section.key === "opts" ? 12 : 32,
            marginTop: 12,
          }}
        >
          {section.expandable ? (
            <CheckboxInput
              id={section.title}
              label={translate(section.title)}
              value={expanded[section.title] === true}
              onChange={(checked) =>
                !setExpanded({
                  ...expanded,
                  [section.title]: checked,
                }) && !onChange("is_legal", checked)
              }
            />
          ) : (
            <h5 className="text-justify">{translate(section.title)}</h5>
          )}
        </Col>
      )}
      {(section.expandable === false || expanded[section.title] === true) &&
        section.fields.map((field) => (
          <Col
            lg={
              field.type === "checkbox" ||
              field.type === "optional_text" ||
              field.type === "radio"
                ? 12
                : 6
            }
            sm={12}
            key={field.name}
            style={{ marginBottom: field.type === "checkbox" ? 24 : null }}
          >
            {field.type === "radio" && (
              <h5 className="text-justify">
                {translate(`pages.contact.form.${contactType}.${field.name}`)}
              </h5>
            )}
            {field.type === "radio" &&
              field.options.map((opt) => (
                <div key={opt} style={{ display: "block", clear: "both" }}>
                  <CheckboxInput
                    id={`${field.name}_${opt}`}
                    label={translate(
                      `pages.contact.form.${contactType}.${field.name}.${opt}`
                    )}
                    value={opt === get(formData, field.name)}
                    onChange={(checked) =>
                      onChange(field.name, checked ? opt : null)
                    }
                  />
                </div>
              ))}
            {field.type === "checkbox" && (
              <CheckboxInput
                id={field.name}
                invalid={get(formStatus, `errors.${field.name}`)}
                value={formData[field.name]}
                label={translate(
                  `pages.contact.form.${contactType}.${field.name}`
                )}
                onChange={(checked) => onChange(field.name, checked)}
                {...field}
              />
            )}
            {field.type === "optional_text" &&
              (formData[field.reference] === true ||
                (field.sub_reference &&
                  field.sub_reference.indexOf(formData[field.reference]) !==
                    -1)) && (
                <Input
                  id={field.name}
                  invalid={get(formStatus, `errors.${field.name}`, false)}
                  value={formData[field.name]}
                  placeholder={
                    field.type !== "file"
                      ? translate(
                          `pages.contact.form.${contactType}.${field.name}.placeholder`
                        )
                      : ""
                  }
                  onChange={(e) =>
                    onChange(
                      field.name,
                      field.type === "file" ? e : e.target.value
                    )
                  }
                  {...field}
                  type="text"
                />
              )}
            {["checkbox", "optional_text", "radio"].indexOf(field.type) ===
              -1 && (
              <Input
                id={field.name}
                invalid={get(formStatus, `errors.${field.name}`, false)}
                value={formData[field.name]}
                label={translate(`pages.contact.form.${field.name}`)}
                placeholder={
                  field.type !== "file"
                    ? translate(`pages.contact.form.${field.name}.placeholder`)
                    : ""
                }
                onChange={(e) =>
                  onChange(
                    field.name,
                    field.type === "file" ? e : e.target.value
                  )
                }
                {...field}
              />
            )}
          </Col>
        ))}
      {section.key === "opts" && (
        <Col lg={12} className="text-justify">
          <br />
          <p> {translate("pages.contact.form.generate_doc.help")}</p>
          <hr />
        </Col>
      )}
      {section.key === "opts" && (
        <Col lg={12} className="text-right">
          <Button
            tag="a"
            target="_blank"
            onClick={handlePrint}
            href={`${BASE_URL}/print?template_name=${contactType}&${stringify({
              ...formData,
              birth_date: moment(formData.birth_date).format("DD/MM/YYYY"),
              doc_identity: null,
              doc_signed: null,
              hash: null,
            })}`}
          >
            <Icon color="white" icon="it-print" />
            {translate("pages.contact.form.generate_doc")}
          </Button>
        </Col>
      )}
    </Row>
  ));
};
export default AdditionalFields;
