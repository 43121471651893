// prettier-ignore
export default {
  "website.date.format.long": "DD.MM.YYYY HH:mm:ss",
  "website.date.format.short": "DD.MM.YYYY",
  "website.loader": "Caricamento in corso...",
  "website.navbar.home": "Home",
  "website.navbar.trasparency": "Trasparenza",
  "website.navbar.sticky-nodes": "Contenuti in Evidenza",
  "website.navbar.contact-us": "Accesso Civico",
  "website.navbar.civic-contact": "Accesso Civico",
  "website.navbar.institutional-portal": "Portale Istituzionale",
  "website.navbar.login": "Accedi all'area riservata",
  "website.navbar.search": "Cerca",
  "website.navbar.node": "Nodo",
  "website.navbar.privacy_policy": "Privacy Policy",
  "website.navbar.sticky_nodes": "Contenuti in Evidenza",
  "website.navbar.tag": "Tag",
  "website.navbar.error403": "Errore 403",
  "website.navbar.error404": "Errore 404",
  "website.owner": "Regione Siciliana",
  "website.name": "Amministrazione Trasparente",
  "website.follow": "Seguici Su",
  "website.search": "Cerca",
  "website.export": "Esporta",
  "website.share": "Condividi",
  "website.clear_filters": "Pulisci filtri",
  "website.show_filters": "Visualizza Filtri",
  "website.hide_filters": "Nascondi Filtri",
  "website.search_keyword": "Chiave di ricerca",
  "website.no_search_results": "Spiacente, nessun risultato trovato in base ai parametri di ricerca inseriti.",
  "website.placeholder.digit_something": "Digita...",

  "website.footer.contacts.title": "Contatti",
  "website.footer.contacts.line1": "Palazzo Orleans - Piazza Indipendenza 21",
  "website.footer.contacts.line2": "90129 Palermo (PA)",
  "website.footer.contacts.line3": "CF 80012000826",
  "website.footer.contacts.email": "segreteria.generale@certmail.regione.sicilia.it",
  "website.footer.section2.title": "URP",
  "website.footer.section2.line1": "Ufficio Relazioni con il Pubblico",
  "website.footer.section3.title": "Trasparenza",
  "website.footer.section3.link1": "Dichiarazione di accessibilità",
  "website.footer.section3.link2": "Statistiche dei siti web",
  "website.footer.section3.link3": "Portale Istituzionale",
  "website.footer.section4.title": "Seguici su",

  "website.cookies.message1": "Questo sito utilizza cookie tecnici, analytics e di terze parti.",
  "website.cookies.message2": "Proseguendo nella navigazione accetti l’utilizzo dei cookie.",
  "website.cookies.buttons.preferences": "Preferenze",
  "website.cookies.buttons.accept": "Accetto",


  "website.footer.links": "Sezione link utili",
  "website.footer.links.legal_notes": "Note Legali",
  "website.footer.links.legal_ads": "Pubblicità legale",
  "website.footer.links.useful": "Siti tematici e link utili",
  "website.footer.links.privacy_and_accessibility": "Privacy e Accessibilità",
  "website.footer.links.privacy_policy": "Privacy Policy",

  "components.node_link_list_item.published": "Pubblicato il %{date}",
  "components.node_link_list_item.modified": "Aggiornato il %{date}",

  "pages.home.roots": "Naviga",
  "pages.home.roots.view": "Consulta",
  "pages.home.last": "Ultimi contenuti pubblicati",
  "pages.home.buttons.collapse": "Nascondi",
  "pages.home.buttons.expand": "Vedi tutte le card",
  "pages.node.tree.view.show": "Mostra intero albero",
  "pages.node.tree.view.hide": "Mostra percorso corrente",
  "pages.node.no_data.title": "Nessun contenuto presente!",
  "pages.node.no_data.message": "Spiacente, all'interno di questo nodo non è presente nessun contenuto.",
  "pages.node.no_data.suggestion": "Se ritieni sia utile un approfondimento puoi contattarci cliccando sul link presente di seguito.",
  "pages.node.no_data.suggestion.contact_us": "Clicca qui per contattarci in merito al nodo corrente.",
  "pages.node.simple_blocks.links": "Link",
  "pages.node.simple_blocks.docs": "Allegati",
  "pages.node.simple_blocks.expiry": "Data di scadenza: %{date}",
  "pages.node.table.prev_page": "Pagina precedente",
  "pages.node.table.next_page": "Pagina successiva",
  "pages.node.published": "Pubblicato il %{date}",
  "pages.node.modified": "Aggiornato il %{date}",
  "pages.node.table.page": "Pagina",
  "pages.node.table.pagination": "Pagina %{page} di %{pages}, %{count} righe totali.",


  "pages.contact.form.name": "Nome",
  "pages.contact.form.name.placeholder": "Inserisci il tuo nome completo",
  "pages.contact.form.email": "Indirizzo E-mail del Mittente",
  "pages.contact.form.email.placeholder": "Inserisci il tuo indirizzo di posta elettronica se vuoi ricevere una risposta",
  "pages.contact.form.subject": "Oggetto",
  "pages.contact.form.message": "Messaggio",
  "pages.contact.form.message.placeholder": "Inserisci il messaggio che vuoi inviare",
  "pages.contact.form.contact_type": "Tipologia Richiesta",
  "pages.contact.form.privacy_policy_link": "Privacy Policy",
  "pages.contact.form.is_policy_accepted": "Ho preso visione dell'informativa sulla privacy",
  "pages.contact.form.submit": "Invia",
  "pages.contact.form.recaptcha": "Inserisci il codice visualizzato sopra",
  "pages.contact.form.choose": "Seleziona una opzione",
  "pages.contact.form.sent.title": "Richiesta inoltrata",
  "pages.contact.form.sent.message": "La tua richiesta è stata inoltrata correttamente, sarai ricontattato il prima possibile.",
  "pages.contact.form.error.title": "Errore imprevisto!",
  "pages.contact.form.error.message": "Siamo spiacenti ma non è stato possibile inoltrare la tua richiesta!",
  "pages.contact.form.invalid.title": "Dati non validi",
  "pages.contact.form.invalid.message": "Uno o più dati inseriti non sono corretti, verificare e riprovare.",
  "pages.contact.form.birth_city": "Comune di Nascita",
  "pages.contact.form.birth_city.placeholder": "Inserisci il tuo comune di nascita",
  "pages.contact.form.birth_date": "Data di nascita",
  "pages.contact.form.birth_date.placeholder": "Inserisci la tau data di nascita",
  "pages.contact.form.residence_city": "Comune di Residenza",
  "pages.contact.form.residence_city.placeholder": "Inserisci il tuo comune di residenza",
  "pages.contact.form.residence_address": "Indirizzo di Residenza",
  "pages.contact.form.residence_address.placeholder": "Inserisci il tuo indirizzo di residenza",
  "pages.contact.form.phone": "Recapito Telefonico",
  "pages.contact.form.phone.placeholder": "Inserisci un recapito telefonico",
  "pages.contact.form.legal_section": "In qualità di legale rappresentante (seleziona per abilitare i dati del soggetto giuridico per conto di cui si scrive)",
  "pages.contact.form.legal_name": "Ragione Sociale",
  "pages.contact.form.legal_name.placeholder": "Inserisci la ragione sociale",
  "pages.contact.form.legal_address": "Indirizzo dell'ente",
  "pages.contact.form.legal_address.placeholder": "Inserisci l'indirizzo dell'ente",
  "pages.contact.form.legal_phone": "Recapito telefonico",
  "pages.contact.form.legal_phone.placeholder": "Inserisci il recapito telefonico dell'ente",
  "pages.contact.form.legal_email": "Indirizzo Email",
  "pages.contact.form.legal_email.placeholder": "Inserisci il recapito email dell'ente",
  "pages.contact.form.docs_section": "Allegati",
  "pages.contact.form.doc_identity": "Copia documento di riconoscimento valido",
  "pages.contact.form.doc_signed": "Copia modulistica con firma autografa o digitale",
  "pages.contact.form.department": "Assessorato/Area/Dipartimento/Servizio",
  "pages.contact.form.department.placeholder": "Area",


  "pages.contact.form.general.opts_section": "Richiede",
  "pages.contact.form.general.flag_opt_1": "Il/i seguente/i documento/i",
  "pages.contact.form.general.flag_opt_2": "Il/i seguente/i dato/i",
  "pages.contact.form.general.flag_opt_3": "La/le seguente/i informazione/i",

  "pages.contact.form.general.msg_opt_1.placeholder": "Inserisci il/i documento/i",
  "pages.contact.form.general.msg_opt_2.placeholder": "Inserisci il/i dato/i",
  "pages.contact.form.general.msg_opt_3.placeholder": "Inserisci la/le informazione/i",
  "pages.contact.form.general.msg_opt_5": "Dichiara di voler ricevere quanto richiesto alternativamente con la seguente modalità, previo rimborso dell'eventuale costo sostenuto dall'Amministrazione per la riproduzione su supporto materiale",
  "pages.contact.form.general.msg_opt_5.a": "consegna brevi manu",
  "pages.contact.form.general.msg_opt_5.b": "trasmissione all'indirizzo di posta elettronica ordinaria/pec",
  "pages.contact.form.general.msg_opt_5.c": "spedizione a mezzo posta, fatto salvo l’eventuale addebito delle spese di spedizione",
  "pages.contact.form.general.msg_opt_6.placeholder": "Specificare altro indirizzo ove diverso da quello in precedenza indicato",

  "pages.contact.form.civic.opts_section": "RICHIEDE, ai sensi e per gli effetti dell’art. 5, comma 1 del decreto legislativo 33/2013, la pubblicazione di quanto segnalato a seguire e contestualmente la comunicazione al sottoscritto dell’avvenuta pubblicazione, con l’indicazione del collegamento ipertestuale al documento/informazione/dato oggetto della presente istanza, CONSIDERANDO:",
  "pages.contact.form.civic.flag_opt_1": "L'omessa pubblicazione del documento/informazione/dato",
  "pages.contact.form.civic.flag_opt_2":"La parziale pubblicazione del documento/informazione/dato",
  "pages.contact.form.civic.flag_opt_4": "Indirizzo per le Comunicazioni (ove diverso da quello precedentemente indicato)",


  "pages.contact.form.civic.msg_opt_1.placeholder": "Descrivi nel dettaglio",
  "pages.contact.form.civic.msg_opt_2.placeholder": "Descrivi nel dettaglio",
  "pages.contact.form.civic.msg_opt_4.placeholder": "Inserisci un indirizzo differente da quello indicato nell'intestazione della richiesta",
  "pages.contact.form.generate_doc": "Genera Modulistica",
  "pages.contact.form.generate_doc.help": "Gentile utente, successivamente alla compilazione di tutti i campi, occorre cliccare sul sotto indicato tasto “Genera modulistica”. La richiesta, firmata digitalmente ovvero stampata e sottoscritta con firma autografa andrà inserita come allegato nel sottostante campo relativo all'istanza. Ciascuno allegato (documento di riconoscimento e istanza) non può superare la dimensione massima di 5 MB.",

  "pages.search.form.fields.keyword": "Chiave di ricerca",
  "pages.search.form.fields.keyword.placeholder": "Inserisci una o più chiavi di ricerca...",
  "pages.search.form.fields.parse_mode": "Modalità di ricerca",
  "pages.search.form.fields.size": "Risultati per pagina",
  "pages.search.form.fields.full_text_groups": "Contenuti in cui ricercare",
  "pages.search.form.fields.full_text_groups.help": "Se non specifico nessun filtro saranno utilizzati tutti i campi di ricerca disponibili.",
  "pages.search.form.full_text_groups.law_reference": "Riferimenti di legge",
  "pages.search.form.full_text_groups.node": "Albero",
  "pages.search.form.full_text_groups.simple_block_item": "Contenuti",
  "pages.search.form.full_text_groups.table_row_cell": "Tabelle",
  "pages.search.form.parse_modes.direct": "Tutte le parole",
  "pages.search.form.parse_modes.terms": "Qualsiasi parola",
  "pages.search.form.parse_modes.phrase": "Frase esatta",
  "pages.search.form.submit": "Cerca",
  "pages.search.results.count": "Trovati %{count} risultati",

  "pages.help.access-requested.title": "Richiesta di accesso",
  "pages.help.access-requested.message": "Benvenuto %{username}, la tua richiesta di accesso è in attesa di essere verificata, riprova in un secondo momento.",

  "pages.error403.message": "Al momento non disponi dei permessi necessari per accedere all'area riservata, ti sarà inoltrata una notifica di attivazione.",
  "pages.error404.message": "Pagina non trovata!",

  "pages.feedback.title": "Meccanismo Feedback",

  "pages.department.filter.no_result": "Selezionare prima l'assessorato"
};
