import React from "react";
import { FormGroup, Label } from "design-react-kit";
import Input from "./Input";
import classNames from "classnames";

const CheckboxInput = ({
  id,
  label,
  value,
  invalid,
  onChange,
  inline = true,
}) => {
  const handleChange = (e) => onChange(e.target.checked);
  return (
    <FormGroup check inline={inline} tag="div">
      <Input
        invalid={invalid}
        checked={value || false}
        id={id}
        type="checkbox"
        onChange={handleChange}
      />
      <Label
        check
        for={id}
        tag="label"
        className={classNames(invalid && "is-invalid")}
      >
        {label}
      </Label>
    </FormGroup>
  );
};
export default CheckboxInput;
