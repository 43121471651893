import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
} from "design-react-kit";
import React, { Fragment, useMemo, useState } from "react";
import { email, required } from "react-admin";
import { useMutation, useTranslate } from "react-admin";

import AdditionalFields from "./AdditionalFields";
import Breadcrumbs from "../../components/Breadcrumbs";
import Input from "../../components/inputs/Input";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import PolicyField from "./PolicyField";
import RecaptchaInput from "../../components/inputs/RecaptchaInput";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import SelectInput from "../../components/inputs/SelectInput";
import TextareaInput from "../../components/inputs/TextareaInput";
import { get } from "lodash";
import { useLocation } from "react-router-dom";
import usePage from "../usePage";

const DEFAULT_FORM_STATE = {
  name: "",
  birth_city: "",
  birth_date: "",
  residence_city: "",
  residence_address: "",

  legal_name: "",
  legal_address: "",
  legal_phone: "",
  legal_email: "",

  phone: "",
  email: "",
  subject: "",
  message: "",
  recaptcha: "",
  contact_type: "civic",
  department_id: "",
  is_legal: false,
  is_policy_accepted: false,

  flag_opt_1: false,
  flag_opt_2: false,
  flag_opt_3: false,
  flag_opt_4: false,
  flag_opt_5: false,
  flag_opt_6: false,

  msg_opt_1: "",
  msg_opt_2: "",
  msg_opt_3: "",
  msg_opt_4: "",
  msg_opt_5: "",
  msg_opt_6: "",

  doc_signed: "",
  doc_identity: "",
};

const REQUIRED_FIELDS = {
  default: ["contact_type"],
  simple: [
    "name",
    "email",
    "subject",
    "message",
    "recaptcha",
    "contact_type",
    "is_policy_accepted",
  ],
  civic: [
    "name",
    "email",
    "birth_city",
    "birth_date",
    "residence_city",
    "residence_address",
    "phone",
    "doc_identity",
    "doc_signed",
  ],
  general: [
    "department_id",
    "name",
    "email",
    "birth_city",
    "birth_date",
    "residence_city",
    "residence_address",
    "phone",
    "doc_identity",
    "doc_signed",
  ],
};

const REQUIRED_FIELDS_FOR_LEGAL = [
  "legal_name",
  "legal_address",
  "legal_phone",
  "legal_email",
];
const ContactPage = (props) => {
  const page = usePage("contact", {}, false);
  const location = useLocation();
  const translate = useTranslate();

  // const pageName = location.pathname.substr(1);

  const [formStatus, setFormStatus] = useState({
    errors: null,
    notify: false,
    notificationStatus: "info",
    notificationTitle: null,
    notificationMessage: null,
  });
  const [formData, setFormData] = useState({
    ...DEFAULT_FORM_STATE,
  });
  const { contactType } = formData;
  const [sendRequest, { loading }] = useMutation(
    {
      type: "post",
      resource: "website",
      payload: {
        method: "contactPage",
        body: {
          ...formData,
          hash: get(formData, "hash", get(page, "hash")),
          contact_type_label: get(
            formData,
            "contact_type_label",
            get(page, "contact_type")
          ),
        },
      },
    },
    {
      onSuccess: ({ data: { success, message, hash } }) => {
        setFormStatus({
          notify: true,
          notificationStatus: success ? "success" : "error",
          notificationTitle: translate(
            `pages.contact.form.${success ? "sent" : "error"}.title`
          ),
          notificationMessage: success
            ? translate("pages.contact.form.sent.message")
            : message,
        });
        if (success) {
          setFormData({ ...DEFAULT_FORM_STATE });
          setTimeout(() => document.location.reload(), 3000);
        }
      },
      onError: () => {
        setFormStatus({
          notify: true,
          notificationStatus: "error",
          notificationTitle: translate("pages.contact.form.error.title"),
          notificationMessage: translate("pages.contact.form.error.message"),
        });
      },
    }
  );

  const showAdditionalFields = useMemo(
    () => get(formData, "contact_type", "simple") !== "simple",
    [formData]
  );
  if (page === false) {
    return <Loader />;
  }

  const contactTypes = get(page, "contact_types", {});
  const departments = get(page, "departments", []);
  const requireDepartmentField =
    get(formData, "contact_type", "none") === "general";
  const options = Object.keys(contactTypes).map((label) => ({
    label,
    value: contactTypes[label],
  }));
  const handleChange = (name, value) =>
    setFormData({ ...formData, [name]: value });

  const handleValidation = (skipFields = []) => {
    let fieldsToValidate =
      REQUIRED_FIELDS[formData.contact_type] || REQUIRED_FIELDS.default;
    if (formData.is_legal) {
      fieldsToValidate = fieldsToValidate.concat(REQUIRED_FIELDS_FOR_LEGAL);
    }
    if (skipFields.length > 0) {
      fieldsToValidate = fieldsToValidate.filter(
        (field) => skipFields.indexOf(field) === -1
      );
    }

    let errors = fieldsToValidate.reduce(
      (errors, field) => ({
        ...errors,
        [field]:
          field.indexOf("email") !== -1
            ? !!email()(formData[field]) || !!required()(formData[field])
            : field === "is_policy_accepted"
            ? !!(formData[field] !== true)
            : !!required()(formData[field]),
      }),
      {}
    );
    if (contactType === "civic" || contactType === "general") {
      let optsRequired = contactType === "civic" ? 2 : 3;
      let optsOneChecked = false;
      for (let i = 1; i <= optsRequired; i++) {
        let checked = formData[`flag_opt_${i}`] === true;
        if (checked) {
          optsOneChecked = true;
          let message = formData[`msg_opt_${i}`];
          if (required()(message)) {
            errors[`msg_opt_${i}`] = true;
          }
        }
        i++;
      }
      if (!optsOneChecked) {
        for (let i = 1; i <= optsRequired; i++) {
          errors[`flag_opt_${i}`] = true;
        }
      }
    }
    const hasErrors =
      Object.keys(errors).filter((errorName) => errors[errorName] === true)
        .length > 0;
    setFormStatus({
      ...formStatus,
      errors,
      notify: hasErrors,
      notificationTitle: hasErrors
        ? translate("pages.contact.form.invalid.title")
        : "",
      notificationMessage: hasErrors
        ? translate("pages.contact.form.invalid.message")
        : "",
      notificationStatus: hasErrors ? "warning" : "info",
    });
    return { errors, hasErrors };
  };
  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { hasErrors } = handleValidation();
    if (!hasErrors) {
      sendRequest();
    }
  };

  return (
    <>
      <Breadcrumbs
        {...props}
        items={[
          {
            to: "/",
            label: "website.navbar.home",
          },
          {
            to: location.pathname,
            label: `Accesso Civico`,
          },
        ]}
      />
      <Section className="pt-0">
        <Container tag="div" className="px-4">
          <Row className="justify-content-md-center mb-3">
            <Col lg={10} md={12} sm={12} xs={12}>
              <form>
                <SectionTitle title={`Accesso Civico`} />
                <Card
                  className="card-bg card-big card-header"
                  noWrapper={false}
                  tag="div"
                >
                  <CardBody>
                    <SelectInput
                      label={translate("pages.contact.form.contact_type")}
                      placeholder={translate("pages.contact.form.choose")}
                      options={options}
                      value={formData.contact_type}
                      onChange={(contact_type, contact_type_label) =>
                        setFormData({
                          ...formData,
                          contact_type,
                          contact_type_label,
                        })
                      }
                    />
                    {requireDepartmentField && (
                      <SelectInput
                        label={translate("pages.contact.form.department")}
                        placeholder={translate(
                          "pages.contact.form.department.placeholder"
                        )}
                        options={departments}
                        invalid={get(formStatus, "errors.department_id", false)}
                        value={formData.department_id}
                        onChange={(value, label, department) =>
                          setFormData({
                            ...formData,
                            department_id: value,
                            department_label: label,
                            department_privacy_policy:
                              department.privacy_policy,
                          })
                        }
                      />
                    )}
                    <Input
                      id="name"
                      invalid={get(formStatus, "errors.name", false)}
                      label={translate("pages.contact.form.name")}
                      placeholder={translate(
                        "pages.contact.form.name.placeholder"
                      )}
                      type="text"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />

                    <Input
                      id="email"
                      invalid={get(formStatus, "errors.email", false)}
                      value={formData.email}
                      label={translate("pages.contact.form.email")}
                      placeholder={translate(
                        "pages.contact.form.email.placeholder"
                      )}
                      type="email"
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                    {showAdditionalFields && (
                      <AdditionalFields
                        onChange={handleChange}
                        handleValidation={handleValidation}
                        formData={formData}
                        formStatus={formStatus}
                      />
                    )}

                    {!showAdditionalFields && (
                      <Input
                        id="subject"
                        invalid={get(formStatus, "errors.subject", false)}
                        label={translate("pages.contact.form.subject")}
                        value={formData.subject}
                        type="text"
                        help={translate(
                          "pages.contact.form.message.placeholder"
                        )}
                        onChange={(e) =>
                          setFormData({ ...formData, subject: e.target.value })
                        }
                      />
                    )}
                    {!showAdditionalFields && (
                      <TextareaInput
                        invalid={get(formStatus, "errors.message", false)}
                        label={translate("pages.contact.form.message")}
                        name="message"
                        value={formData.message}
                        onChange={(message) =>
                          setFormData({ ...formData, message })
                        }
                      />
                    )}
                    <RecaptchaInput
                      hash={get(formData, "hash", get(page, "hash"))}
                      invalid={get(formStatus, "errors.recaptcha", false)}
                      label={translate("pages.contact.form.recaptcha")}
                      name="recaptcha"
                      value={formData.recaptcha}
                      onChange={(e) =>
                        setFormData({ ...formData, recaptcha: e.target.value })
                      }
                    />
                    {!showAdditionalFields && (
                      <PolicyField
                        formStatus={formStatus}
                        formData={formData}
                        onChange={handleChange}
                      />
                    )}
                    {formStatus.notify && (
                      <Notification
                        header={formStatus.notificationTitle}
                        state={formStatus.notificationStatus}
                        dismissable
                        withIcon
                        onDismiss={() =>
                          setFormStatus({ ...formStatus, notify: false })
                        }
                      >
                        <Fragment>{formStatus.notificationMessage}</Fragment>
                      </Notification>
                    )}
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button
                      type="submit"
                      color="primary"
                      tag="button"
                      className="mr-2"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {translate("pages.contact.form.submit")}
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default ContactPage;
