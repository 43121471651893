import { Col, Container, Row } from "design-react-kit";

import NodeCard from "./NodeCard";
import React from "react";
import { get } from "lodash";

const Nodes = ({ items }) => {
  return (
    <Container tag="div">
      <Row tag="div">
        {items.map((item) => (
          <Col
            key={get(item, "uuid")}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="mb-2"
          >
            <NodeCard item={item} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Nodes;
