import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "./Header";
import Loader from "../../components/Loader";
import React from "react";
import Roots from "./Roots";
import Section from "../../components/Section";
import usePage from "../usePage";

// import Last from "./Last";

// import SectionTitle from "../../components/SectionTitle";

const HomePage = (props) => {
  const data = usePage("home", {}, false);
  if (data === false) {
    return <Loader />;
  }
  return (
    <>
      <Breadcrumbs
        {...props}
        items={[
          {
            to: "/",
            label: "website.navbar.home",
          },
          {
            to: "/",
            label: "website.navbar.trasparency",
          },
        ]}
      />
      <Section className="pt-4 pb-4">
        <Roots roots={data.roots} />
      </Section>
      {/* <Section className="pt-5 pb-2">
        <SectionTitle title="pages.home.last" />
        <Last last={data.last} />
      </Section> */}

      <Section className="pt-0">
        <br />
        <Header settings={data.settings} />
      </Section>
    </>
  );
};

export default HomePage;
