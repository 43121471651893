import React from "react";
import { get } from "lodash";
import { Col, Container, Row } from "design-react-kit";
import HeaderCard from "./HeaderCard";

const Header = ({ settings }) => {
  return (
    <Container tag="div">
      <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
        <Col className="mb-3" lg="7" tag="div" xs="12">
          <HeaderCard
            title={get(settings, "pages.home.card1.title")}
            subtitle1={get(settings, "pages.home.card1.subtitle1")}
            subtitle2={get(settings, "pages.home.card1.subtitle2")}
            content1={get(settings, "pages.home.card1.content1")}
            content2={get(settings, "pages.home.card1.content2")}
            readmoreLink={{
              name: get(settings, "pages.home.card1.link.name"),
              href: get(settings, "pages.home.card1.link.href"),
            }}
          />
        </Col>
        <Col className="mb-3" lg="5" tag="div" xs="12">
          <HeaderCard
            title={get(settings, "pages.home.card2.title")}
            content1={get(settings, "pages.home.card2.content1")}
            content2={get(settings, "pages.home.card2.content2")}
            bodyLink={{
              name: get(settings, "pages.home.card2.law_link.name"),
              href: get(settings, "pages.home.card2.law_link.href"),
            }}
            readmoreLink={{
              name: get(settings, "pages.home.card2.link.name"),
              href: get(settings, "pages.home.card2.link.href"),
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default Header;
