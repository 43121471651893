import { get } from "lodash";
import makeid from "./utils/makeid";

export const getHeaders = (settings, authenticate = false) => {
  const csrfToken = localStorage.getItem("csrf_token");
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");
  const auth = username && password ? `${username}:${password}` : null;

  let addHeaders = get(settings, "headers", undefined);
  const additionalHeaders = addHeaders
    ? typeof addHeaders === "function"
      ? addHeaders()
      : addHeaders
    : {};

  const basicHeaders = {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    ...additionalHeaders,
  };
  if (csrfToken !== null) {
    basicHeaders["X-CSRF-Token"] = csrfToken;
  }

  if (authenticate && auth !== null) {
    basicHeaders.Authorization = `Basic ${btoa(auth)}`;
  }
  return basicHeaders;
};

export const getHeadersForCustomResource = () =>
  getHeaders({
    headers: {
      "Content-Type": "application/json",
    },
  });

const createEntity = (item, settings = {}) => {
  const entityType = get(item, "type", null);
  if (entityType === null) {
    return Promise.reject("Invalid item type for: " + JSON.stringify(item));
  }
  const attributes = { ...item };
  delete attributes.id;
  delete attributes.status;
  delete attributes.changed;
  delete attributes.created;
  delete attributes.default_langcode;
  delete attributes.drupal_internal__id;
  delete attributes.drupal_internal__vid;
  delete attributes.langcode;
  delete attributes.relationships;
  delete attributes.revision_created;
  delete attributes.revision_log_message;
  delete attributes.revision_translation_affected;

  const type = get(item, "type");
  const request = {
    requestId: `${type}-${makeid(20)}`,
    uri: `/api/${type}`,
    action: "create",
    headers: getHeaders(settings),
    body: JSON.stringify({
      data: {
        type: get(item, "type"),
        attributes,
        relationships: get(item, "relationships", {}),
      },
    }),
  };
  return Promise.resolve({
    relationship: {
      id: `{{${request.requestId}.body@$.data.id}}`,
      type: get(item, "type"),
    },
    request,
  });
};
const updateEntity = (item, settings = {}) => {
  const entityType = get(item, "type", null);
  if (entityType === null) {
    return Promise.reject(`Invalid Entity Type`);
  }
  const type = get(item, "type");
  const request = {
    requestId: `${type}-${makeid(20)}`,
    uri: `/api/${type}/${get(item, "id")}`,
    action: "update",
    headers: getHeaders(settings),
    body: JSON.stringify({
      data: {
        type: get(item, "type"),
        id: item.id,
        attributes: { ...item, relationships: undefined, type: undefined },
        relationships: get(item, "relationships"),
      },
    }),
  };
  return Promise.resolve({
    relationship: {
      id: `{{${request.requestId}.body@$.data.id}}`,
      type: get(item, "type"),
    },
    request,
  });
};

/**
 * Upload new file in to Drupal.
 * The upload feature requires RestUI plugin enabled in to the installation.
 *
 * @param {string} referenceEntity The referenced entity for which you are creating the file.
 * @param {string} type Type associated to the file to upload, necessary to create the final URL where file will be uploaded.
 * @param {File} rawFile The rawFile object to upload.
 */
const uploadFile = async (referenceEntity, type, rawFile, settings) => {
  const csrfToken = localStorage.getItem("csrf_token");
  const baseEntityType = get(
    settings,
    `map.${referenceEntity}`,
    referenceEntity
  );
  const apiUrl = get(settings, "apiUrl");
  const url = `${apiUrl}/${baseEntityType}/${type}`;
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");
  const headers = new Headers({
    "Content-Type": "application/octet-stream",
    "Content-Disposition": `filename="${rawFile.name}"`,
    "X-CSRF-Token": csrfToken,
    Authorization: `Basic ${btoa(username + ":" + password)}`,
  });
  const formData = new FormData();
  formData.append("file", rawFile);
  const {
    data: { id },
  } = await fetch(url, {
    method: "POST",
    body: formData,
    headers: headers,
  }).then((response) => response.json());
  return Promise.resolve(id);
};

const isRelationshipReference = (item) => {
  const type = get(item, "type", null);
  const relationships = get(item, "relationships", null);
  const id = get(item, "id", null);
  return (
    type !== null &&
    id !== null &&
    Object.keys(item).length === (relationships !== null ? 3 : 2)
  );
};
const prepareDataForEntity = async (
  entity,
  relationshipName,
  settings,
  referenceEntity
) => {
  const file = get(entity, "rawFile", null);
  const type = get(entity, "type", null);
  if (type === "file--file" || (file !== null && file instanceof File)) {
    if (get(entity, "id", null) !== null) {
      // File already exists, nothing to do.
      return Promise.resolve({
        relationship: {
          id: entity.id,
          type: entity.type,
        },
        request: null,
      });
    }
    const id = await uploadFile(
      referenceEntity,
      relationshipName,
      file,
      settings
    );
    return Promise.resolve({
      relationship: { id, type: "file--file" },
      request: null,
    });
  } else if (get(entity, "id", null) === null) {
    return await createEntity(entity, settings);
  } else {
    return await updateEntity(entity, settings);
  }
};
const prepareDataForRelationships = async (
  data,
  settings,
  referenceEntity = ""
) => {
  const relationships = get(data, "relationships", null);
  const requests = [];
  if (relationships === null) {
    return Promise.resolve({ requests, relationships: {} });
  }
  return await Object.keys(relationships).reduce(
    async (juiceReduction, relationshipName) => {
      const { reducedData, requests } = await juiceReduction;
      let relationshipData = get(
        relationships,
        `${relationshipName}.data`,
        null
      );
      const type = get(data, "type");
      const parser = get(settings, `parse.${type}`);
      if (parser) {
        relationshipData = parser(relationshipData);
      }
      const saveableList = get(settings, `saveable.${type}`, []);
      const saveable = saveableList.indexOf(relationshipName) !== -1;
      if (saveable && Array.isArray(relationshipData)) {
        for (var i = 0; i < relationshipData.length; i++) {
          let arrayItem = relationshipData[i];
          if (isRelationshipReference(arrayItem)) {
            continue;
          }

          let type = get(arrayItem, "type", null);
          if (type === null) {
            type = get(settings, `map.${relationshipName}`, null);
            if (type === null) {
              return Promise.reject(
                `Invalid item type for: ${relationshipName}`
              );
            }
            arrayItem.type = type;
          }

          const dataForRelationships = await prepareDataForRelationships(
            arrayItem,
            settings,
            relationshipName
          );
          arrayItem.relationships = get(dataForRelationships, "reducedData");
          const generatedRequests = get(dataForRelationships, "requests");
          if (generatedRequests.length > 0) {
            generatedRequests.forEach((req) => requests.push(req));
            // requests.push(generatedRequests);
          }

          const { relationship, request } = await prepareDataForEntity(
            arrayItem,
            relationshipName,
            settings,
            referenceEntity
          );
          relationshipData[i] = relationship;
          if (request) {
            request.waitFor = generatedRequests.map((req) => req.requestId);
            requests.push(request);
          }
        }
      } else if (saveable && relationshipData !== null) {
        if (!isRelationshipReference(relationshipData)) {
          let type = get(relationshipData, "type", null);
          if (type === null) {
            type = get(settings, `map.${relationshipName}`, null);
            relationshipData.type = type;
          }
          const dataForRelationships = await prepareDataForRelationships(
            relationshipData,
            settings,
            relationshipName
          );
          relationshipData.relationships = get(
            dataForRelationships,
            "reducedData"
          );

          const generatedRequests = get(dataForRelationships, "requests");
          if (generatedRequests.length > 0) {
            generatedRequests.forEach((req) => requests.push(req));
            // requests.push(generatedRequests);
          }
          const { relationship, request } = await prepareDataForEntity(
            relationshipData,
            relationshipName,
            settings,
            referenceEntity
          );
          relationshipData = relationship;
          if (request) {
            request.waitFor = generatedRequests.map((req) => req.requestId);
            requests.push(request);
          }
        }
      }
      let newData = {
        ...reducedData,
        [relationshipName]: {
          ...relationships[relationshipName],
          data: {
            ...relationshipData,
          },
        },
      };
      return Promise.resolve({ reducedData: newData, requests });
    },
    Promise.resolve({ requests, reducedData: {} })
  );
};

const subrequests = async (data, settings) => {
  const { requests } = await prepareDataForRelationships(data, settings);
  const id = get(data, "id", null);
  const type = get(data, "type");
  const action = id !== null ? "update" : "create";
  const uri = action === "create" ? `${type}` : `${type}/${id}`;
  const lastRequest = {
    requestId: "last",
    uri: `/api/${uri}`,
    action,
    headers: getHeaders(),
    body: JSON.stringify({
      data,
    }),
    waitFor: requests.map((req) => req.requestId),
  };

  requests.push(lastRequest);

  return requests;
};

export default subrequests;
