import { Col, Container, Row } from "design-react-kit";

import Breadcrumbs from "../../components/Breadcrumbs";
import React from "react";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import { useQueryWithStore } from "react-admin";
import { get } from "lodash";

const FeedbackPage = (props) => {
  const { data, loaded } = useQueryWithStore({
    type: "get",
    resource: "website",
    payload: {
      method: 'feedbackPage',
    },
  });

  const attributes = loaded && get(data[0], "attributes", []);
  const title = get(attributes, "title", "");
  const body = get(attributes, "body", "");
  return (
    <>
      <Breadcrumbs
        {...props}
        items={[
          {
            to: "/",
            label: "website.navbar.home",
          },
          {
            to: "/meccanismo-feedback",
            label: "Meccanismo Feedback",
          },
        ]}
      />
      <Section className="pt-0">
        <SectionTitle title={title} />
        <Container tag="div">
          <Row className="justify-content-md-center">
            <Col lg={10} md={12}>
              <div dangerouslySetInnerHTML={{__html: body}}></div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default FeedbackPage;
