import React from "react";
import classNames from "classnames";
const TextareaInput = ({
  value,
  maxLength = 0,
  help,
  name,
  label,
  invalid,
  onChange,
}) => (
  <div className={classNames("form-group", invalid && "is-invalid")}>
    <label htmlFor="message" className="active pb-2">
      {label}
    </label>
    <textarea
      id="message"
      rows="3"
      onChange={(e) => onChange(e.target.value)}
      value={value}
    ></textarea>
    {maxLength > 0 && (
      <small className="form-help text-muted">
        {value.length}/{maxLength}
      </small>
    )}
    {help && <small className="form-help text-muted">{help}</small>}
  </div>
);

export default TextareaInput;
