import React, { useMemo } from "react";
import { get } from "lodash";
import { useTranslate } from "react-admin";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  FormText,
  Label,
} from "design-react-kit";
import Input from "../../components/inputs/Input";
import SelectInput from "../../components/inputs/SelectInput";

const SearchForm = ({
  page,
  search,
  loading,
  onSearchChange,
  onSearchRequest,
}) => {
  const fullTextFieldGroups = useMemo(() => get(page, "full_text_groups", []), [
    page,
  ]);

  const parseModes = ["phrase", "terms", "direct"];
  const translate = useTranslate();

  return (
    <form>
      <Card
        className="card-bg card-big card-header card-search"
        noWrapper={false}
        tag="div"
      >
        <CardBody>
          <Input
            id="keyword"
            value={get(search, "keyword")}
            label={translate("pages.search.form.fields.keyword")}
            placeholder={translate(
              "pages.search.form.fields.keyword.placeholder"
            )}
            type="text"
            onChange={(evt) => onSearchChange("keyword", evt.target.value)}
          />
          <SelectInput
            name="size"
            value={get(search, "size")}
            label={translate("pages.search.form.fields.size")}
            options={[
              {
                label: "5",
                value: "5",
              },
              {
                label: "10",
                value: "10",
              },
              {
                label: "20",
                value: "25",
              },
            ]}
            onChange={(value) => onSearchChange("size", value)}
          />
          <fieldset>
            <legend>{translate("pages.search.form.fields.parse_mode")}</legend>
            {parseModes.map((parseMode) => (
              <FormGroup key={parseMode} check tag="div">
                <Input
                  id={parseMode}
                  name="parseMode"
                  type="radio"
                  checked={get(search, "parse_mode") === parseMode}
                  onChange={(evt) => onSearchChange("parse_mode", parseMode)}
                />
                <Label check for={parseMode} tag="label">
                  {translate(`pages.search.form.parse_modes.${parseMode}`)}
                </Label>
              </FormGroup>
            ))}
          </fieldset>
          <fieldset>
            <legend>
              {translate("pages.search.form.fields.full_text_groups")}
            </legend>
            {fullTextFieldGroups.map((field) => (
              <FormGroup key={field} check inline tag="div">
                <Input
                  id={field}
                  type="checkbox"
                  checked={
                    get(search, "full_text_groups", []).indexOf(field) !== -1
                  }
                  onChange={(evt) =>
                    onSearchChange(
                      "full_text_groups",
                      evt.target.checked
                        ? get(search, "full_text_groups", []).concat(field)
                        : get(search, "full_text_groups", []).filter(
                            (f) => f !== field
                          )
                    )
                  }
                />
                <Label check for={field} tag="label">
                  {translate(`pages.search.form.full_text_groups.${field}`)}
                </Label>
              </FormGroup>
            ))}

            <FormText>
              {translate("pages.search.form.fields.full_text_groups.help")}
            </FormText>
          </fieldset>
        </CardBody>
        <CardFooter className="text-right">
          <Button
            type="submit"
            color="primary"
            tag="button"
            className="mr-2"
            onClick={onSearchRequest}
            disabled={loading || get(search, "keyword", "").length <= 3}
          >
            {translate("pages.search.form.submit")}
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
};
export default SearchForm;
