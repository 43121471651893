/**
 * All configuration parameters should be placed here.
 */
let baseUrl = `//${document.location.host}`;
if (baseUrl.indexOf(":300") !== -1) {
  baseUrl = "//localhost";
}
export const VERSION = "1.0.10";
export const BASE_URL = baseUrl;
export const API_URL = `${BASE_URL}/api`;

export const API_CONFIG = {
  apiUrl: API_URL,
  /**
   * Lista dei mapping diretti tra proprietà di una risorsa (singola o lista) ed il suo relativo tipo.
   * Il mapping è fondamentale per gestire il salvataggio/aggiornamento delle relazioni "many-to-many".
   */
  map: {
    doc: "tree-node-doc",
    docs: "tree-node-doc",
    props: "tree-node-prop",
    law_references: "law-reference",
    simple_block_items: "tree-node-simple-block-item",
    table_column_defs: "tree-node-table-column-def",
    table_rows: "tree-node-table-row",
    table_row_cells: "tree-node-table-row-cell",
    link: "tree-node-link",
    links: "tree-node-link",
  },
  alias: {
    "monitoring-content": "tree-node",
  },
  /**
   * Indica l'elenco delle risorse per cui è meglio utilizzare subrequests per il salvataggio dei dati.
   * Teoricamente è meglio utilizzarlo per qualsiasi risorsa MA, generalmente, essendo la maggior parte
   * delle risorse in rapporto 1:1 con le richieste di creazione/modifica, anche la semplice API va bene.
   *
   * Subrequests usa lo standard Blueprint:
   * @see https://www.drupal.org/project/subrequests
   * @see https://www.lullabot.com/articles/incredible-decoupled-performance-with-subrequests
   */
  subrequests: ["tree-node"],
  /**
   * Lista delle inclusioni da caricare subito durante la lettura delle liste o dei recuperi diretti (ONE) di una entità.
   */
  include: {
    user: ["roles", "user_picture"],
    "tree-node": [
      "revision_user",
      "props",
      "tags",
      "docs",
      "docs.file",
      "user_id",
      "parent_id",
      "simple_block_items",
      "simple_block_items.docs",
      "simple_block_items.docs.file",
      "simple_block_items.links",
      "table_column_defs",
      "table_rows.table_row_cells",
      "table_rows.table_row_cells.docs",
      "table_rows.table_row_cells.docs.file",
      "table_rows.table_row_cells.doc",
      "table_rows.table_row_cells.doc.file",
      "table_rows.table_row_cells.links",
      "table_rows.table_row_cells.link",
    ],
    "tree-group": ["users", "parent_id", "users.roles"],
  },
  /**
   * Indica delle inclusioni per cui è possibile effettuare la creazione/modifica delle dipendenze.
   * Serve per evitare che l'utente possa effettuare operazioni non consentite ed ottenere messaggi di errore.
   */
  saveable: {
    "tree-node": [
      "props",
      "docs",
      "simple_block_items",
      "table_column_defs",
      "table_rows",
    ],
    "tree-node-doc": ["file"],
    "tree-node-simple-block-item": ["docs", "links"],
    "tree-node-table-row": ["table_row_cells"],
    "tree-node-table-row-cell": ["doc", "docs", "link", "links"],
  },
  /**
   * Lista delle risorse esposte da Drupal e che non richiedono basic_auth, se specificate all'interno di questa lista
   * non saranno soggette all'applicazione degli headers necessari per il riconoscimento.
   * @see https://www.drupal.org/docs/8/api/restful-web-services-api/custom-rest-resources
   */
  custom: {
    "language-message/load": {
      auth: false,
    },
  },
  /**
   * Configurazione custom da applicare per i filtri di ricerca su una risorsa.
   * Il dataProvider cercherà eventuali match all'interno di questo elenco per valutare la generazione di una query string custom invece che quella standard.
   *
   * Una funzione custom di generazione può ricevere in input tre parametri di cui i primi due obbligatori ed il terzo opzionale.
   * - query: attuale contentuo dell'oggetto query che sarà successivamente trasformato in stringa.
   * - filter: attuale oggetto contenente tutti i filtri che l'utente vuole applicare per la ricerca.
   * - allParams: contiene tutti i parametri oggetto della query di tipo lista (paginazione, pagina e ordinamento)
   */
  filter: {
    user: (query, filter) => {
      if (filter.name) {
        return {
          ...query,
          ...f("name", "CONTAINS", filter.name),
        };
      }
      return query;
    },
    "app-setting": (query, filter) => {
      let finalQuery = { ...query };
      if (filter.q) {
        finalQuery = {
          ...finalQuery,
          ...g("OR"),
          ...f("name", "CONTAINS", filter.q, "OR"),
          ...f("value", "CONTAINS", filter.q, "OR"),
          ...f("group", "CONTAINS", filter.q, "OR"),
        };
      }
      if (filter.group) {
        finalQuery = {
          ...finalQuery,
          ...f("group", "=", filter.group),
        };
      }
      return finalQuery;
    },
    "law-reference": (query, filter) => {
      if (filter.q) {
        return {
          ...query,
          ...g("OR"),
          ...f("name", "CONTAINS", filter.q, "OR"),
          ...f("title", "CONTAINS", filter.q, "OR"),
          ...f("description", "CONTAINS", filter.q, "OR"),
        };
      } else if (filter.id) {
        return {
          ...query,
          ...f("id", "IN", filter.id),
        };
      }
      return query;
    },
    "tree-node": (query, filter) => {
      let finalQuery = { ...query };
      if (filter.id) {
        finalQuery = {
          ...finalQuery,
          ...f("parent_id.vid", "=", filter.id),
        };
      } else {
        finalQuery = {
          ...finalQuery,
          ...f("parent_id.vid", "IS NULL"),
        };
      }
      if (filter.q) {
        finalQuery = {
          ...finalQuery,
          ...g("OR"),
          ...f("name", "CONTAINS", filter.q, "OR"),
          ...f("description", "CONTAINS", filter.q, "OR"),
        };
      }
      if (filter.content_type) {
        finalQuery = {
          ...finalQuery,
          ...f("content_type", "=", filter.content_type),
        };
      }
      if (filter.allowed_content_type) {
        finalQuery = {
          ...finalQuery,
          ...f("allowed_content_type", "=", filter.allowed_content_type),
        };
      }
      return finalQuery;
    },
    "tree-group": (query, filter) => {
      if (filter.id) {
        return {
          ...query,
          ...f("parent_id.vid", "=", filter.id),
        };
      } else {
        return {
          ...query,
          ...f("parent_id.vid", "IS NULL"),
        };
      }
    },
    "tree-node-tag": (query, filter) => {
      if (filter.q) {
        return {
          ...query,
          ...f("name", "CONTAINS", filter.q),
        };
      } else if (filter.id) {
        return {
          ...query,
          ...f("id", "IN", filter.id),
        };
      }
      return query;
    },
  },
};

const g = (name) => {
  return {
    [`filter[${name}][group][conjunction]`]: name,
  };
};
const f = (name, operator, value, group = undefined) => {
  let defaults = {
    [`filter[${name}][condition][path]`]: name,
    [`filter[${name}][condition][value]`]: value,
    [`filter[${name}][condition][operator]`]: operator,
  };
  if (group) {
    defaults[`filter[${name}][condition][memberOf]`] = group;
  }
  return defaults;
};
