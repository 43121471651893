import AutocompleteInput from "../../../components/inputs/AutocompleteInput";
import Input from "../../../components/inputs/Input";
import PropTypes from "prop-types";
import React, { useEffect, useContext } from "react";
import { get, isObject } from "lodash";
import { useTranslate, useQueryWithStore } from "react-admin";
import { DepartmentContext } from "../NodePage";

const TableFilter = ({ filter, value, onChange }) => {

  const translate = useTranslate();
  const departmentContext = useContext(DepartmentContext);
  const type = get(filter, "def_type");

  const { data, loaded } = useQueryWithStore({
    type: "get",
    resource: "website",
    payload: {
      method: 'dslists',
      name: value?.value || "",
      type: type,
      defId: filter.id
    },
  });

  const handleChange = async(e, chnageType) => {
    const value = get(e, "target.value", e);
    onChange(filter, value);
    let arr = []
    if(chnageType === 'assessorato') {
        if(data.length > 0) {
          data.map((value) =>  arr.push(value?.name))
          departmentContext.dipartimentoState.setDipartimentValue(arr);
        } else {
          departmentContext.dipartimentoState.setDipartimentValue([]);
        }
      }
  };
  
  useEffect(() => {
    if (data && data.length > 0) {
      let arr = []
      if(type === 'assessorato') {
        data.map((value) =>  arr.push(value?.name))
        departmentContext.dipartimentoState.setDipartimentValue(arr);
      }
    } else {  departmentContext.dipartimentoState.setDipartimentValue([]); }
  }, [data])

  switch (type) {
    default:
      return (
        <Input
          label={get(filter, "name")}
          type={type}
          value={isObject(value) ? value.value : value || ""}
          onChange={(e) => handleChange(e, 'default')}
          placeholder={translate("website.placeholder.digit_something")}
        />
      );
    case "autocomplete":
      return (
        <AutocompleteInput
          label={get(filter, "name")}
          options={get(filter, "options", []).map((value) => ({
            value,
            label: value,
          }))}
          onChange={(e) => handleChange(e, 'autocomplete')}
          value={value?.value && value || ""}
          type={type}
          filter={filter}
        />
      );
    case "assessorato":
      return (
        <AutocompleteInput
          label={get(filter, "name")}
          options={get(filter, "options", []).map((value) => ({
            value,
            label: value,
          }))}
          onChange={(e) => handleChange(e, 'assessorato')}
          value={value?.value && value || ""}
          type={type}
          filter={filter}
        />
      );
    case "dipartimento":
      return (
        <AutocompleteInput
          key={ departmentContext.dipartimentoState.dipartimentValue }
          label={get(filter, "name")}
          options={departmentContext.dipartimentoState.dipartimentValue.map((value) => ({
            value,
            label: value,
          }))}
          onChange={(e) => handleChange(e, 'dipartimento')}
          value={value?.value && value || ""}
          type={type}
          filter={filter}
        />
      );
  }
};

TableFilter.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    def_type: PropTypes.string.isRequired,
    options: PropTypes.array,
  }),
};

export default TableFilter;
