import React, { useMemo } from "react";

import { get } from "lodash";

const { Row, Col, Container, Alert } = require("design-react-kit");
const { useTranslate } = require("react-admin");
const EmptyMessae = ({ node, className }) => {
  const translate = useTranslate();
  const customMessage = useMemo(() => {
    const emptyMessage = get(node, "empty_message");
    return emptyMessage && emptyMessage !== null && emptyMessage.length > 0
      ? emptyMessage
      : null;
  }, [node]);
  return (
    <Container tag="div" className={className}>
      <Row tag="div" className="justify-content-center">
        <Col lg={12}>
          <Alert
            closeAriaLabel="Close"
            color="warning"
            className="mb-4"
            fade
            isOpen
            tag="div"
            transition={{
              appear: true,
              baseClass: "fade",
              baseClassActive: "show",
              enter: true,
              exit: true,
              in: true,
              mountOnEnter: false,
              tag: "div",
              timeout: 150,
              unmountOnExit: true,
            }}
          >
            {customMessage === null && (
              <>
                <h4 className="alert-heading">
                  {translate("pages.node.no_data.title")}
                </h4>
                <p>{translate("pages.node.no_data.message")}</p>
              </>
            )}
            {customMessage !== null && (
              <div dangerouslySetInnerHTML={{ __html: customMessage }} />
            )}
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default EmptyMessae;
