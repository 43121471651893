import { Icon, LinkListItem } from "design-react-kit";
import React, { useMemo } from "react";

import PropTypes from "prop-types";
import classNames from "classnames";
import { get } from "lodash";
import moment from "moment";
import { useTranslate } from "react-admin";

const NodeLinkListItem = ({ node }) => {
  const translate = useTranslate();
  const dateFormat = translate("website.date.format.short");
  const { published, modified } = useMemo(() => {
    const isPublishedVisible = get(node, "is_published_visible") === "1";
    const isModifiedVisible = get(node, "is_modified_visible") === "1";
    let published = isPublishedVisible ? get(node, "published") : null;
    if (published) {
      published = moment(published).format(dateFormat);
    }
    let modified = isModifiedVisible ? get(node, "modified") : null;
    if (modified) {
      modified = moment(modified).format(dateFormat);
    }
    return {
      published,
      modified,
    };
  }, [node, dateFormat]);

  return (
    <LinkListItem
      key={get(node, "uuid")}
      tag="a"
      className={classNames("right-icon list-item")}
      href={`/node?uuid=${get(node, "uuid")}`}
    >
      <h4>{get(node, "tree_name", "").substr(1).split("/").join(" / ")}</h4>
      <i className="it-chevron-right right" />
      <Icon
        className="icon-right"
        color="primary"
        icon="it-chevron-right"
        padding={false}
        size=""
        style={{
          ariaHidden: true,
        }}
      />
      <div>
        <div dangerouslySetInnerHTML={{ __html: get(node, "description") }} />
        <br />
        <small>
          {get(node, "law_references").map((lr, i) => (
            <a rel="noopener noreferrer" target="_blank" key={i} href={lr.href}>
              {lr.label}
            </a>
          ))}
        </small>
      </div>
      <p>
        <strong>
          {published !== null &&
            translate("components.node_link_list_item.published", {
              date: published,
            })}
          {published !== null && modified !== null && " | "}
          {modified !== null &&
            translate("components.node_link_list_item.modified", {
              date: modified,
            })}
        </strong>
        <br />
        {get(node, "tags").map((tag) => (
          <a href={`/tag/${tag}`}>{tag.startsWith("#") ? tag : `#${tag}`}</a>
        ))}
      </p>
    </LinkListItem>
  );
};

NodeLinkListItem.propTypes = {
  node: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tree_name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    changed: PropTypes.string.isRequired,
    published: PropTypes.string,
    modified: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    law_references: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default NodeLinkListItem;
