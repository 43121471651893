import "./theme.css";
// import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import "./index.css";
import "typeface-titillium-web";
import "typeface-roboto-mono";
import "typeface-lora";

import { API_CONFIG, API_URL } from "./config";
import {
  AuthContext,
  DataProviderContext,
  TranslationProvider,
} from "react-admin";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import ContactPage from "./pages/contact";
import ErrorPage from "./pages/error";
import FeedbackPage from "./pages/feedback";
import HelpPage from "./pages/help";
import HomePage from "./pages/home";
import Layout from "./components/Layout";
import NodePage from "./pages/node";
import PrivacyPolicyPage from "./pages/privacy-policy";
import { Provider } from "react-redux";
import React from "react";
import SearchPage from "./pages/search";
import StickyNodesPage from "./pages/sticky-nodes";
import TagPage from "./pages/tag";
import authProvider from "./authProvider";
import createAdminStore from "./createAdminStore";
import { createHashHistory } from "history";
import dataProvider from "./dataProvider";
import messages from "./i18n";
import polyglotI18nProvider from "ra-i18n-polyglot";
import withContext from "recompose/withContext";

const dp = dataProvider(API_URL, API_CONFIG);
const i18nProvider = polyglotI18nProvider((locale) => {
  return messages.it;
});
const history = createHashHistory();
const App = () => (
  <Provider
    store={createAdminStore({
      authProvider,
      dataProvider: dp,
      history,
    })}
  >
    <AuthContext.Provider value={authProvider}>
      <DataProviderContext.Provider value={dp}>
        <TranslationProvider locale="en" i18nProvider={i18nProvider}>
          <BrowserRouter>
            <Layout>
              <Switch>
                <Route path="/node" component={NodePage} />
                <Route path="/nodo" component={NodePage} />
                <Route path="/sticky-nodes" component={StickyNodesPage} />
                <Route path="/tag/:tag" component={TagPage} />
                <Route
                  path="/contenuti-in-evidenza"
                  component={StickyNodesPage}
                />

                <Route path="/search" component={SearchPage} />
                <Route path="/cerca" component={SearchPage} />

                <Route path="/contact-us" component={ContactPage} />
                <Route path="/contattaci" component={ContactPage} />

                <Route path="/civic-contact" component={ContactPage} />
                <Route path="/accesso-civico" component={ContactPage} />

                <Route path="/privacy-policy" component={PrivacyPolicyPage} />
                <Route path="/error403" component={ErrorPage} />
                <Route path="/error404" component={ErrorPage} />
                <Route path="/meccanismo-feedback" component={FeedbackPage} />

                <Route path="/help" component={HelpPage} />
                <Route exact path="/" component={HomePage} />
                <Route path="*" component={Error} />
              </Switch>
            </Layout>
          </BrowserRouter>
        </TranslationProvider>
      </DataProviderContext.Provider>
    </AuthContext.Provider>
  </Provider>
);

export default withContext({}, () => ({}))(App);
