import { Col, Container, Icon, Row } from "design-react-kit";
import { useQueryWithStore } from "react-admin";

import Link from "./Link";
import Logos from "./Logos";
import React from "react";
import logo from "../assets/logo.png";
import { useTranslate } from "react-admin";
import { get } from "lodash";
import { BASE_URL } from "../config";

const Footer = ({ town }) => {
  const translate = useTranslate();
  const { data, loaded } = useQueryWithStore({
    type: "get",
    resource: "website",
    payload: {
      method: 'footerList',
    },
  });
  const attributes = loaded && get(data, "attributes", []);
  const generalBgColor = get(attributes, "general_bg_color", "")
  const footerTop = get(attributes, "footer_top_images", {});
  const middleFirstTitle = get(attributes, "middle_first_column_title", "");
  const middleFirstDesc = get(attributes, "middle_first_column_content", "");
  const middleSecondTitle = get(attributes, "middle_second_column_title", "");
  const middleSecondDesc = get(attributes, "middle_second_column_content", "");
  const middleThirdTitle = get(attributes, "middle_third_column_title", "");
  const middleThird = get(attributes, "middle_third_column_link_data", []);
  const bottomFirstColumn = get(attributes, "bottom_first_column_link_data", []);
  const bottomSecondColumn = get(attributes, "bottom_second_column_link_data", []);
  document.documentElement.style.setProperty('--footer-bg-color', generalBgColor);

  const urlRegx = /^https?:\/\//;
  
  return (
    <footer className="it-footer">
      <div className="it-footer-main">
        <Container>
          <section>
            <Row className="clearfix">
              <Col sm={12}>
                <div className="it-brand-wrapper">
                  <Link to={footerTop.link} className="">
                    <img
                      src={urlRegx.test(footerTop.src) ? footerTop.src : BASE_URL+footerTop.src}
                      width="54"
                      alt={translate("website.owner")}
                    />
                    <div className="it-brand-text">
                      <h2 className="no_toc">{translate("website.owner")}</h2>
                      <h3 className="no_toc d-none d-md-block">
                        {translate("website.name")}
                      </h3>
                    </div>
                  </Link>
                </div>
              </Col>
            </Row>
          </section>
          <section className="py-4 res-footer-blocks">
            <div className="row">
              <div className="col-lg-3 col-md-3 pb-2">
                <div
                  id="it-region-footer-first"
                  className="region footer_first"
                >
                  <nav
                    role="navigation"
                    aria-labelledby="block-contatti-menu"
                    id="block-contatti"
                    data-block-plugin-id="block_content:6f2a0cc7-c271-48f7-998a-327ef4d94935"
                  >
                    <h4 id="block-contatti-menu">
                      {middleFirstTitle}
                    </h4>

                    <div dangerouslySetInnerHTML={{__html: middleFirstDesc}}></div>
                  </nav>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 pb-2">
                <div
                  id="it-region-footer-second"
                  className="region footer_second"
                >
                  <nav
                    role="navigation"
                    aria-labelledby="block-urp-menu"
                    id="block-urp"
                    data-block-plugin-id="system_menu_block:urp"
                  >
                    {/* <h4 id="block-urp-menu">
                      {translate("website.footer.section2.title")}
                    </h4>

                    <div className="link-list-wrapper">
                      <ul className="link-list">
                        <li>
                          <a className="list-item" href="/">
                            {translate("website.footer.section2.line1")}
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </nav>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 pb-2">
                <div
                  id="it-region-footer-third"
                  className="region footer_third"
                >
                  <nav
                    role="navigation"
                    aria-labelledby="block-trasparenza-menu"
                    id="block-trasparenza"
                    data-block-plugin-id="system_menu_block:transparency"
                  >
                    <h4 id="block-trasparenza-menu">{middleSecondTitle}</h4>

                    <div className="link-list-wrapper">
                      <ul className="link-list">
                        <li>
                          <a
                            className="list-item"
                            href="https://regione.sicilia.it"
                          >
                            <span dangerouslySetInnerHTML={{__html: middleSecondDesc}}></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 pb-2">
                <div className="it-socials d-none d-md-flex">
                  <span>{middleThirdTitle}</span>
                  <ul>
                    {middleThird.map((middle, key) => (
                      <li key={key}>
                        <a 
                          href={middle.middle_third_column_link_text}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img src={urlRegx.test(middle.middle_third_column_link_images.src) ? middle.middle_third_column_link_images.src : BASE_URL+middle.middle_third_column_link_images.src} />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </Container>
      </div>
      <div className="it-footer-small-prints clearfix">
        <Container>
          <div className="row">
            <div className="col-xl-8 col-12">
              <Container>
                <h3 className="sr-only">{translate("website.footer.links")}</h3>
                <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                  {bottomFirstColumn.map((link, index) => (
                    <li className="list-inline-item" key={link.order_index}>
                      <Link href={link.bottom_first_column_link_url} title={link.bottom_first_column_link_description}>
                        {link.bottom_first_column_link_description}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Container>
            </div>
            <div className="col-xl-4 col-12">
              <div id="it-region-footer-credits" className="region footer_credits">
                <div
                  id="it-block-footerlogosimagesblock"
                  data-block-plugin-id="footer_images_block"
                >
                  <div className="d-flex justify-content-xl-end footer-logos">
                    {bottomSecondColumn.map(logo => (
                      <div className="logo" key={logo.order_index}>
                        <img src={urlRegx.test(logo.bottom_second_column_link_image.src) ? logo.bottom_second_column_link_image.src : BASE_URL+logo.bottom_second_column_link_image.src} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
