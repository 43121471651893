import React from "react";
import { Card, CardBody, CardTitle, CardReadMore } from "design-react-kit";
import { get } from "lodash";
import { useTranslate } from "react-admin";

const NodeCard = ({ item }) => {
  const translate = useTranslate();
  return (
    <Card className="card-bg card-big card-root" noWrapper={false} tag="div">
      <CardBody>
        <CardTitle tag="h4">{get(item, "name")}</CardTitle>
        <CardReadMore
          href={`/node?uuid=${get(item, "uuid")}`}
          iconName="it-chevron-right"
          tag="a"
          text={translate("pages.home.roots.view")}
        />
      </CardBody>
    </Card>
  );
};
export default NodeCard;
