import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Icon,
  Row,
} from "design-react-kit";
import React, { useMemo, useState } from "react";

import PropTypes from "prop-types";
import TableFilters from "../node/content-types/TableFilters";
import TableHeader from "../node/content-types/TableHeader";
import TablePaginator from "../node/content-types/TablePaginator";
import TableRow from "../node/content-types/TableRow";
import classNames from "classnames";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import downloadCSV from "../../utils/downloadCSV";
import { get } from "lodash";
import parseLink from "../node/parseLink";
import { useTranslate } from "react-admin";

const createSearchableKeyword = (row) => {
  return [
    get(row, "raw_value"),
    get(row, "doc.name"),
    get(row, "doc.description"),
    get(row, "doc.url"),
    get(row, "link.name"),
    get(row, "link.description"),
    get(row, "link.url"),
    get(row, "links", [])
      .map((link) =>
        [get(link, "name"), get(link, "url"), get(link, "description")].join(
          "-"
        )
      )
      .join("~"),
    get(row, "docs", [])
      .map((doc) =>
        [get(doc, "name"), get(doc, "url"), get(doc, "description")].join(",")
      )
      .join("~"),
  ].join("_");
};

const createTable = (data, sort, direction, filterValues = undefined) => {
  const headers = get(data, "[0].table.headers", []);
  const searchable = data.some((d) => d.table.is_searchable);
  const exportable = data.some((d) => d.table.is_exportable);

  let rows = data.reduce(
    (rows, tableRows) => rows.concat(get(tableRows, "table.rows", [])),
    []
  );
  const totalRows = rows.length;

  rows = rows.filter((row) => {
    if (!filterValues || Object.keys(filterValues).length === 0) {
      return true;
    }
    let hasMatch = false;
    let cells = Object.keys(filterValues);
    for (var i = 0; i < cells.length; i++) {
      const cell = cells[i];
      if (cell === "*") {
        hasMatch =
          Object.keys(row)
            .map((rowKey) => createSearchableKeyword(row[rowKey]))
            .join("|")
            .toLowerCase()
            .indexOf(filterValues[cell].toLowerCase()) !== -1;
        return hasMatch;
      } else if (filterValues[cell] === "") {
        continue;
      }

      hasMatch =
        get(row, `${cell}.raw_value`) &&
        get(row, `${cell}.raw_value`)
          .toLowerCase()
          .indexOf(filterValues[cell].toLowerCase()) !== -1;
      if (!hasMatch) {
        break;
      }
    }
    return hasMatch;
  });

  if (sort !== null) {
    const sortColumn = headers.find((header) => header.id === sort);
    // const sortType = get(sortColumn, "def_type")

    rows = rows.sort((a, b) => {
      const r =
        (get(a, `${get(sortColumn, "id")}.raw_value`) >
          get(b, `${get(sortColumn, "id")}.raw_value`)) -
        (get(a, `${get(sortColumn, "id")}.raw_value`) <
          get(b, `${get(sortColumn, "id")}.raw_value`));

      return direction === "ASC" ? r : -r;
    });
  }

  return {
    headers,
    rows,
    totalRows,
    searchable,
    exportable,
  };
};

const exportTable = (columns, rows) => {
  const headers = columns.map((column) => get(column, "name"));
  const linksType = ["one_link", "many_links"];
  const docsType = ["one_doc", "many_docs"];
  const hasLinks = columns.some(
    (c) => linksType.indexOf(get(c, "def_type", null)) !== -1
  );
  const hasDocs = columns.some(
    (c) => docsType.indexOf(get(c, "def_type", null)) !== -1
  );

  const data = rows.reduce((tableRows, row, index) => {
    const baseRow = columns.map((column) => {
      const cell = get(row, `[${get(column, "id")}]`);
      switch (get(column, "def_type")) {
        case "text":
        case "autocomplete":
        case "number":
        case "email":
          const value = get(cell, "raw_value", "");
          return value === null ? "" : value;
        default:
          return "";
      }
    });

    if (!hasLinks && !hasDocs) {
      return tableRows.concat([baseRow]);
    }
    const countBefore = tableRows.length;
    columns.forEach((column) => {
      const type = get(column, "def_type");
      const cell = get(row, `[${get(column, "id")}]`);

      if (linksType.indexOf(type) !== -1) {
        const links = get(cell, "links", []);
        const link = get(cell, "link", null);
        links
          .concat([link])
          .filter((l) => l !== null)
          .forEach((link) => {
            const linkRow = [...baseRow];
            linkRow.splice(column.order_index, 1, parseLink(get(link, "url")));
            tableRows.push(linkRow);
          });
      } else if (docsType.indexOf(type) !== -1) {
        const docs = get(cell, "docs", []);
        const doc = get(cell, "doc", null);
        docs
          .concat([doc])
          .filter((d) => d !== null)
          .forEach((doc) => {
            const docRow = [...baseRow];
            docRow.splice(column.order_index, 1, parseLink(get(doc, "url")));
            tableRows.push(docRow);
          });
      }
    });
    const countAfter = tableRows.length;
    if (countAfter === countBefore) {
      tableRows.push(baseRow);
    }
    return tableRows;
  }, []);

  const csv = convertToCSV([headers].concat(data), {
    fields: columns.map((column) => get(column, "name")),
    delimiter: ";",
  });
  downloadCSV(csv, "export");
};

const SearchTableRows = ({ data, showAsResult = false }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const translate = useTranslate();
  const [table, setTable] = useState({
    sort: "",
    page: 0,
    size: 10,
    direction: null,
    expanded: [],
  });
  const handlePageChange = (page) => setTable({ ...table, page });
  const handleSortChange = (sort, direction) =>
    setTable({ ...table, sort, direction });
  const toggleRow = (index, e) => {
    e.stopPropagation();
    e.preventDefault();
    const { expanded } = table;
    if (expanded.indexOf(index) !== -1) {
      setTable({ ...table, expanded: expanded.filter((e) => e !== index) });
    } else {
      setTable({ ...table, expanded: expanded.concat([index]) });
    }
  };
  const handleFiltersChange = (filters) => {
    setFilterValues(filters);
  };
  const handleFiltersClear = () => setFilterValues({});
  const handleFiltersToggle = () => setShowFilters(!showFilters);

  const { sort, direction, expanded, page, size } = table;
  const { headers, totalRows, rows, searchable, exportable } = useMemo(
    () => createTable(data, sort, direction, filterValues),
    [data, sort, direction, filterValues]
  );
  const { columns, expandable } = useMemo(
    () => ({
      columns: headers.filter((h) => h.is_preview),
      expandable: headers.filter((h) => !h.is_preview),
    }),
    [headers]
  );

  const pages = useMemo(
    () => Math.floor(rows.length / size) + (rows.length % size !== 0 ? 1 : 0),
    [rows, size]
  );
  const filters = useMemo(
    () => headers.filter((h) => h.is_filterable),
    [headers]
  );
  const { from, to } = useMemo(
    () => ({
      from: page * size,
      to: page * size + size,
    }),
    [page, size]
  );
  const handleExport = () => exportTable(headers, rows);
  if (totalRows === 0) {
    return null;
  }

  return (
    <Row tag="div" className="px-3">
      {!showAsResult && (searchable || filters.length > 0) && (
        <Col lg={12} className="mt-3">
          <Card className="card-bg card-filter pt-3">
            <CardBody>
              <CardTitle
                tag="h5"
                onClick={handleFiltersToggle}
                style={{ cursor: "pointer" }}
              >
                <Icon icon={showFilters ? "it-collapse" : "it-expand"} />
                {translate(
                  `website.${showFilters ? "hide_filters" : "show_filters"}`
                )}
              </CardTitle>
              <Collapse isOpen={showFilters}>
                <TableFilters
                  searchable={searchable}
                  exportable={exportable}
                  filters={filters}
                  onExport={handleExport}
                  onChange={handleFiltersChange}
                  onClear={handleFiltersClear}
                />
              </Collapse>
            </CardBody>
          </Card>
        </Col>
      )}
      {!showAsResult && !searchable && exportable && (
        <Col lg={12} className="text-right">
          <Button color="primary" onClick={handleExport}>
            {translate("website.export")}
          </Button>
        </Col>
      )}
      <Col lg={12} className={classNames(!showAsResult && "mt-3")}>
        <Card
          className={classNames(!showAsResult && "card-bg card-content-type")}
        >
          <CardBody>
            <Row tag="div">
              <Col
                lg={12}
                className={classNames(
                  !showAsResult && "mt-3 scrollable-horizontal"
                )}
              >
                <table className="table">
                  <thead>
                    <tr>
                      {expandable.length > 0 && <th>&nbsp;</th>}
                      {columns.map((column) => (
                        <TableHeader
                          key={get(column, "id")}
                          column={column}
                          sort={sort}
                          direction={direction}
                          onSort={handleSortChange}
                        />
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.length === 0 && totalRows > 0 && (
                      <tr>
                        <td colSpan={columns.length + 1}>
                          {translate("website.no_search_results")}
                        </td>
                      </tr>
                    )}
                    {rows
                      .filter((_, index) => index >= from && index <= to)
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          expandable={expandable}
                          expanded={expanded}
                          columns={columns}
                          toggleRow={toggleRow}
                          index={index}
                          row={row}
                        />
                      ))}
                  </tbody>
                </table>
              </Col>
              {!showAsResult && (
                <Col lg={4} md={5} sm={12} xs={12}>
                  <p className="table-pagination">
                    {translate("pages.node.table.pagination", {
                      page: page + 1,
                      pages,
                      count: rows.length,
                    })}
                  </p>
                </Col>
              )}
              {!showAsResult && (
                <Col lg={8} md={7} sm={12} xs={12}>
                  {pages > 1 && (
                    <TablePaginator
                      pages={pages}
                      size={size}
                      page={page}
                      onChange={handlePageChange}
                    />
                  )}
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const docPropTypes = PropTypes.shape({
  description: PropTypes.string.isRequired,
  doc_type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});
const linkPropTypes = PropTypes.shape({
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

SearchTableRows.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      table: PropTypes.shape({
        headers: PropTypes.arrayOf(
          PropTypes.shape({
            def_type: PropTypes.string.isRequired,
            is_filterable: PropTypes.bool,
            is_preview: PropTypes.bool,
            name: PropTypes.string.isRequired,
            order_index: PropTypes.number.isRequired,
          })
        ),
        rows: PropTypes.arrayOf(
          PropTypes.objectOf(
            PropTypes.shape({
              raw_value: PropTypes.string,
              doc: docPropTypes,
              docs: PropTypes.arrayOf(docPropTypes),
              link: linkPropTypes,
              links: PropTypes.arrayOf(linkPropTypes),
            })
          )
        ),
      }),
    })
  ),
};

export default SearchTableRows;
