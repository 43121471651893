import React from "react";
import { useTranslate } from "react-admin";
import { CookieBar, CookieBarButton, CookieBarButtons } from "design-react-kit";
import { instanceOf } from "prop-types";
import { compose } from "recompose";
import { withCookies, Cookies } from "react-cookie";

const COOKIE_POLICY_ACCEPTED = "cookie_policy_accepted";

const CookiePolicyBar = ({ cookies }) => {
  const translate = useTranslate();
  const cookiePolicyAccepted = cookies.get(COOKIE_POLICY_ACCEPTED) || false;
  const handleAcceptCookies = (e) => {
    e.preventDefault();
    e.stopPropagation();
    cookies.set(COOKIE_POLICY_ACCEPTED, true);
    return false;
  };
  if (cookiePolicyAccepted) {
    return null;
  }
  return (
    <CookieBar>
      <p>
        {translate("website.cookies.message1")}
        <br />
        {translate("website.cookies.message2")}
      </p>
      <CookieBarButtons>
        <CookieBarButton onClick={handleAcceptCookies} href="#">
          {translate("website.cookies.buttons.accept")}
        </CookieBarButton>
      </CookieBarButtons>
    </CookieBar>
  );
};

CookiePolicyBar.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
};
export default compose(withCookies)(CookiePolicyBar);
