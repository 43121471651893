import {
  Button,
  Collapse,
  Header,
  HeaderContent,
  HeaderLinkZone,
  HeaderRightZone,
  HeaderSearch,
  HeaderToggler,
  Headers,
  Icon,
  Nav,
  NavItem,
  NavLink,
} from "design-react-kit";
import React, { useState } from "react";

import Link from "./Link";
import PropTypes from "prop-types";
import Symbol from "../assets/symbol.svg";
import logo from "../assets/logo.png";
import { useLocation } from "react-router-dom";
import { useTranslate } from "react-admin";

const SlimHeader = () => {
  const translate = useTranslate();
  return (
    <Header type="slim" theme={"dark"}>
      <HeaderContent className="it-header-socials-zone-wrapper">
        <HeaderLinkZone />
        <HeaderRightZone>
          <Button
            color="primary"
            size="full"
            className="btn-icon"
            href="/login"
          >
            <span className="rounded-icon">
              <Icon color="primary" icon="it-user" />
            </span>
            <span className="d-none d-lg-block">
              {translate("website.navbar.login")}
            </span>
          </Button>
        </HeaderRightZone>
      </HeaderContent>
    </Header>
  );
};

const CenterHeader = () => {
  const translate = useTranslate();

  return (
    <Header
      type="center"
      theme="light"
      style={{
        backgroundImage: `url(${Symbol})`,
        backgroundPosition: "right 56%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "665px 700px",
        backgroundPositionX: "61%",
      }}
    >
      <HeaderContent>
        <div className="it-header-center-content-wrapper">
          <div className="it-brand-wrapper">
            <Link href="/" style={{ color: "black" }}>
              <img id="logo" src={logo} alt={translate("website.name")} />
              <div className="it-brand-text">
                <h2>{translate("website.owner")}</h2>
                <h3>{translate("website.name")}</h3>
              </div>
            </Link>
          </div>
        </div>
        <HeaderRightZone>
          <HeaderSearch
            label={translate("website.search")}
            href="/search"
            iconName="it-search"
          />
        </HeaderRightZone>
      </HeaderContent>
    </Header>
  );
};

const NavHeader = () => {
  const translate = useTranslate();
  const [isOpen, toggleDropdown] = useState(false);
  const { pathname } = useLocation();
  const navLinks = [
    "sticky-nodes",
    // "contact-us"
  ];
  const navHrefs = {
    "sticky-nodes": "contenuti-in-evidenza",
    "contact-us": "contattaci",
  };
  return (
    <Header type="navbar" theme={"light"}>
      <HeaderContent expand="lg" megamenu>
        <HeaderToggler
          onClick={() => toggleDropdown(!isOpen)}
          aria-controls="nav1"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <Icon icon="it-burger" />
        </HeaderToggler>
        <Collapse
          isOpen={isOpen}
          navbar
          header
          onOverlayClick={() => toggleDropdown(!isOpen)}
        >
          <div className="menu-wrapper">
            <Nav navbar>
              <NavItem>
                <NavLink to="/" tag={Link} active={pathname === "/"}>
                  {translate("website.navbar.trasparency")}
                </NavLink>
              </NavItem>
              {navLinks.map((navLink) => (
                <NavItem key={navLink}>
                  <NavLink
                    to={`/${navHrefs[navLink]}`}
                    tag={Link}
                    active={pathname.indexOf(`/${navHrefs[navLink]}`) === 0}
                  >
                    {translate(`website.navbar.${navLink}`)}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <Nav navbar className="navbar-secondary">
              <NavItem>
                <NavLink to="https://regione.sicilia.it/" tag={Link}>
                  {translate("website.navbar.institutional-portal")}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Collapse>
      </HeaderContent>
    </Header>
  );
};

const CompleteHeader = ({ sticky, theme, type, ...props }) => {
  return (
    <Headers sticky={!!sticky}>
      <SlimHeader />
      <div className="it-nav-wrapper">
        <CenterHeader />
        <NavHeader />
      </div>
    </Headers>
  );
};

CompleteHeader.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  type: PropTypes.string,
};

CompleteHeader.defaultProps = {
  type: "default",
};

export default CompleteHeader;
