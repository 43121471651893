import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Icon,
  Row,
} from "design-react-kit";
import React, { useMemo, useState, useEffect } from "react";

import PropTypes from "prop-types";
import TableFilters from "./TableFilters";
import TableHeader from "./TableHeader";
import TablePaginator from "./TablePaginator";
import TableRow from "./TableRow";
import classNames from "classnames";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import downloadCSV from "../../../utils/downloadCSV";
import { get } from "lodash";
import parseLink from "../parseLink";
import { useTranslate } from "react-admin";
import { useLocation, useHistory } from "react-router-dom";
import EmptyMessage from "../EmptyMessage";

const createTable = (data, sort, direction, filterValue = undefined) => {
  const headers = get(data, "[0].table.headers", []);
  const searchable = data.some((d) => d.table.is_searchable);
  const exportable = data.some((d) => d.table.is_exportable);

  let rows = [], totalCount = 0;
  data.forEach((dataItem) => {
    rows.push(get(dataItem, "table.rows[0]", []));
    totalCount = dataItem?.content_count
  });

  const totalRows = rows.length;

  return {
    headers,
    rows,
    totalRows,
    searchable,
    exportable,
    totalCount,
  };
};

const exportTable = (columns, rows) => {
  const headers = columns.map((column) => get(column, "name"));
  const linksType = ["one_link", "many_links"];
  const docsType = ["one_doc", "many_docs"];
  const hasLinks = columns.some(
    (c) => linksType.indexOf(get(c, "def_type", null)) !== -1
  );
  const hasDocs = columns.some(
    (c) => docsType.indexOf(get(c, "def_type", null)) !== -1
  );

  const data = rows.reduce((tableRows, row, index) => {
    const baseRow = columns.map((column) => {
      const cell = get(row, `[${get(column, "id")}]`);
      switch (get(column, "def_type")) {
        case "text":
        case "autocomplete":
        case "number":
        case "email":
          const value = get(cell, "raw_value", "");
          return value === null ? "" : value;
        case "assessorato":
        case "dipartimento":
          let val = get(cell, "raw_value_name", get(cell, "raw_value", ""));
          return val === null ? "" : val;
        default:
          return "";
      }
    });

    if (!hasLinks && !hasDocs) {
      return tableRows.concat([baseRow]);
    }
    const countBefore = tableRows.length;
    columns.forEach((column) => {
      const type = get(column, "def_type");
      const cell = get(row, `[${get(column, "id")}]`);

      if (linksType.indexOf(type) !== -1) {
        const links = get(cell, "links", []);
        const link = get(cell, "link", null);
        links
          .concat([link])
          .filter((l) => l !== null)
          .forEach((link) => {
            const linkRow = [...baseRow];
            linkRow.splice(column.order_index, 1, parseLink(get(link, "url")));
            tableRows.push(linkRow);
          });
      } else if (docsType.indexOf(type) !== -1) {
        const docs = get(cell, "docs", []);
        const doc = get(cell, "doc", null);
        docs
          .concat([doc])
          .filter((d) => d !== null)
          .forEach((doc) => {
            const docRow = [...baseRow];
            docRow.splice(column.order_index, 1, parseLink(get(doc, "url")));
            tableRows.push(docRow);
          });
      }
    });
    const countAfter = tableRows.length;
    if (countAfter === countBefore) {
      tableRows.push(baseRow);
    }
    return tableRows;
  }, []);

  const csv = convertToCSV([headers].concat(data), {
    fields: columns.map((column) => get(column, "name")),
    delimiter: ";",
  });
  downloadCSV(csv, "export");
};

const TableRows = ({
  data,
  setFilterValue,
  setSortValue,
  sortValue,
  pageNumber,
  setPageNumber,
  changes,
  setChanges,
  showFilters,
  setShowFilters,
  filterValue,
  pageData,
}) => {
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);

  const currentLocation = document.location.toString();
  const queryParams = currentLocation.split("?");
  const qs = new URLSearchParams(queryParams[1]);
  const filter = JSON.parse(qs.get("p") || "0");
  
  const translate = useTranslate();
  const [table, setTable] = useState({
    sort: sortValue.sort,
    direction: sortValue.direction,
    page: pageNumber,
    size: 10,
    expanded: [],
  });
  const handlePageChange = (page) => {
    setTable({ ...table, page })
    setPageNumber(page);
  };
  const handleSortChange = (sort, direction) => {
    setTable({ ...table, sort, direction });
    setSortValue({sort, direction});
  }
  const toggleRow = (index, e) => {
    e.stopPropagation();
    e.preventDefault();
    const { expanded } = table;
    if (expanded.indexOf(index) !== -1) {
      setTable({ ...table, expanded: expanded.filter((e) => e !== index) });
    } else {
      setTable({ ...table, expanded: expanded.concat([index]) });
    }
  };
  const handleFiltersChange = (filters) => {
    handlePageChange(0);
    setFilterValue(filters);
  };
  const handleFiltersClear = () => {
    qs.delete('contentTreePath');
    history.replace(`?${qs.toString().replace(encodeURIComponent('#/'), '')}`);

    setFilterValue({});
    setChanges("");
  };
  const handleFiltersToggle = () => setShowFilters(!showFilters);

  useEffect(() => {
    setTable({ ...table, page: filter });
  }, [filter])

  const { sort, direction, expanded, page, size } = table;
  const { headers, totalRows, rows, searchable, exportable, totalCount } = useMemo(
    () => createTable(data, sortValue.sort, sortValue.direction, filterValue),
    [data, sortValue.sort, sortValue.direction, filterValue, changes]
  );
  const { columns, expandable } = useMemo(
    () => ({
      columns: headers.filter((h) => h.is_preview),
      expandable: headers.filter((h) => !h.is_preview),
    }),
    [headers]
  );

  const isFiltersOnly = get(pageData, 'filter_only');
  const pageNode = get(pageData, 'node', []);

  const pages = useMemo(
    () => Math.floor(totalCount / size) + (totalCount % size !== 0 ? 1 : 0),
    [rows, size]
  );
  const filters = useMemo(
    () => headers.filter((h) => h.is_filterable),
    [headers]
  );

  const handleExport = () => exportTable(headers, rows);

  return (
    <Row tag="div" className="px-3">
      {(searchable || filters.length > 0) && (
        <Col lg={12} className="mt-3">
          <Card className="card-bg card-filter pt-3">
            <CardBody>
              <CardTitle
                tag="h5"
                onClick={handleFiltersToggle}
                style={{ cursor: "pointer" }}
              >
                <Icon icon={showFilters ? "it-collapse" : "it-expand"} />
                {translate(
                  `website.${showFilters ? "hide_filters" : "show_filters"}`
                )}
              </CardTitle>
              <Collapse isOpen={showFilters}>
                <TableFilters
                  searchable={searchable}
                  exportable={exportable}
                  filters={filters}
                  onExport={handleExport}
                  onChange={handleFiltersChange}
                  onClear={handleFiltersClear}
                  changes={changes}
                  filterValue={filterValue}
                  setChanges={setChanges}
                />
              </Collapse>
            </CardBody>
          </Card>
        </Col>
      )}
      {rows.length === 0 ? (
        <EmptyMessage node={pageNode} className="px-4 pt-4" />
      ) : (
        !isFiltersOnly ? (
          <Col lg={12} className={classNames("mt-3")}>
            <Card
              className={classNames("card-bg card-content-type")}
            >
              <CardBody>
                <Row tag="div">
                  <Col
                    lg={12}
                    className={classNames(
                      "mt-3 scrollable-horizontal"
                    )}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          {expandable.length > 0 && <th>&nbsp;</th>}
                          {columns.map((column) => (
                            <TableHeader
                              key={get(column, "id")}
                              column={column}
                              sort={sortValue.sort}
                              direction={sortValue.direction}
                              onSort={handleSortChange}
                            />
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {rows
                          // .filter((_, index) => index >= from && index <= to)
                          .map((row, index) => (
                            <TableRow
                              key={index}
                              expandable={expandable}
                              expanded={expanded}
                              columns={columns}
                              toggleRow={toggleRow}
                              index={index}
                              row={row}
                            />
                          ))}
                      </tbody>
                    </table>
                  </Col>
                  {totalRows > 0 && (
                    <Col lg={4} md={5} sm={12} xs={12}>
                      <p className="table-pagination">
                        {translate("pages.node.table.pagination", {
                          page: pageNumber + 1,
                          pages,
                          count: totalCount,
                        })}
                      </p>
                    </Col>
                  )}
                  {totalRows > 0 && (
                    <Col lg={8} md={7} sm={12} xs={12}>
                      {pages > 1 && (
                        <TablePaginator
                          pages={pages}
                          size={size}
                          page={pageNumber}
                          onChange={handlePageChange}
                        />
                      )}
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        ) : (
          <EmptyMessage node={pageNode} className="px-4 pt-4" />
        )
      )}
    </Row>
  );
};

const docPropTypes = PropTypes.shape({
  description: PropTypes.string.isRequired,
  doc_type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});
const linkPropTypes = PropTypes.shape({
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

TableRows.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      table: PropTypes.shape({
        headers: PropTypes.arrayOf(
          PropTypes.shape({
            def_type: PropTypes.string.isRequired,
            is_filterable: PropTypes.bool,
            is_preview: PropTypes.bool,
            name: PropTypes.string.isRequired,
            order_index: PropTypes.number.isRequired,
          })
        ),
        rows: PropTypes.arrayOf(
          PropTypes.objectOf(
            PropTypes.shape({
              raw_value: PropTypes.string,
              doc: docPropTypes,
              docs: PropTypes.arrayOf(docPropTypes),
              link: linkPropTypes,
              links: PropTypes.arrayOf(linkPropTypes),
            })
          )
        ),
      }),
    })
  ),
};

export default TableRows;
