/* eslint react/prefer-stateless-function: 0 */

import React from "react";
import PropTypes from "prop-types";
import { Util } from "reactstrap";
import { get } from "lodash";
import isNumber from "is-number";

import InputContainer from "./InputContainer";
import { Icon } from "design-react-kit";
import {
  getTag,
  getFormControlClass,
  getClasses,
  getInfoTextControlClass,
} from "./utils";

const { deprecated, warnOnce } = Util;

const propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  accept: PropTypes.arrayOf(PropTypes.string),
  maxSize: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  infoText: PropTypes.string,
  normalized: PropTypes.bool,
  bsSize: PropTypes.string,
  state: deprecated(
    PropTypes.string,
    'Please use the props "valid" and "invalid" to indicate the state.'
  ),
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  static: deprecated(PropTypes.bool, 'Please use the prop "plaintext"'),
  plaintext: PropTypes.bool,
  addon: PropTypes.bool,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  type: "text",
};

class Input extends React.Component {
  state = {
    isFocused: false,
    hidden: true,
    icon: true,
  };

  toggleFocusLabel = () => {
    this.setState({
      isFocused: true,
    });
  };

  toggleBlurLabel = (e) => {
    if (e.target.value === "") {
      this.setState({
        isFocused: !this.state.isFocused,
      });
    }
  };

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden, icon: !this.state.icon });
  };

  handleFileChange(e) {
    // get the files
    let files = e.target.files;
    const { accept, maxSize } = this.props;

    if (files.length === 0) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    let file = files[0];
    if (accept && accept.length > 0) {
      const valid = new RegExp(
        "(" + accept.join("|").replace(/\./g, "\\.") + ")$"
      ).test(file.name);
      if (!valid) {
        e.preventDefault();
        e.stopPropagation();
        e.target.value = null;
        this.props.onChange(null);
        return false;
      }
    }

    if (maxSize > 0) {
      const size = maxSize * 1024 * 1024;
      if (file.size > size) {
        e.preventDefault();
        e.stopPropagation();
        e.target.value = null;
        this.props.onChange(null);
        return false;
      }
    }

    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      let fileInfo = {
        name: file.name,
        type: file.type,
        size: Math.round(file.size / 1000) + " kB",
        base64: reader.result,
        file: file,
      };

      this.props.onChange(fileInfo);
    };
  }

  render() {
    const {
      id,
      className,
      cssModule,
      type,
      state,
      tag,
      addon,
      static: staticInput,
      plaintext,
      innerRef,
      label,
      infoText,
      placeholder,
      normalized,
      value,
      maxSize,
      accept,
      ...attributes
    } = this.props;

    let { bsSize, valid, invalid } = attributes;
    delete attributes.bsSize;
    delete attributes.valid;
    delete attributes.invalid;
    const Tag = getTag({ tag, plaintext, staticInput, type });
    const formControlClass = getFormControlClass(
      {
        plaintext,
        staticInput,
        type,
        addon,
      },
      cssModule
    );
    const infoTextControlClass = getInfoTextControlClass(
      { valid, invalid },
      cssModule
    );

    if (state && valid == null && invalid == null) {
      invalid = state === "danger";
      valid = state === "success";
    }

    if (attributes.size && !isNumber(attributes.size)) {
      warnOnce(
        'Please use the prop "bsSize" instead of the "size" to bootstrap\'s input sizing.'
      );
      bsSize = attributes.size;
      delete attributes.size;
    }

    if (Tag === "input" || typeof tag !== "string") {
      attributes.type = type;
    }

    if (
      attributes.children &&
      !(
        plaintext ||
        staticInput ||
        type === "select" ||
        typeof Tag !== "string" ||
        Tag === "select"
      )
    ) {
      warnOnce(
        `Input with a type of "${type}" cannot have children. Please use "value"/"defaultValue" instead.`
      );
      delete attributes.children;
    }

    const inputPassword = attributes.type === "password";
    const inputFile = attributes.type === "file";

    // Styling
    const { activeClass, infoTextClass, inputClasses, wrapperClass } =
      getClasses(
        className,
        {
          valid,
          invalid,
          bsSize,
          placeholder,
          value,
          label,
          infoText,
          normalized,
          inputPassword,
          formControlClass,
          infoTextControlClass,
          isFocused: this.state.isFocused,
        },
        cssModule
      );

    // set of attributes always shared by the Input components
    const sharedAttributes = {
      id,
      onFocus: this.toggleFocusLabel,
      onBlur: this.toggleBlurLabel,
      value: value,
      ref: innerRef,
    };

    // set of attributes always shared by the wrapper component
    const containerProps = {
      id,
      activeClass,
      label,
      infoTextClass,
      infoText,
      wrapperClass,
    };

    if (placeholder) {
      return (
        <InputContainer {...containerProps}>
          <Tag
            {...attributes}
            {...sharedAttributes}
            className={inputClasses}
            placeholder={placeholder}
          />
        </InputContainer>
      );
    }

    if (inputPassword) {
      return (
        <InputContainer {...containerProps}>
          <Tag
            {...attributes}
            {...sharedAttributes}
            type={this.state.hidden ? "password" : "text"}
            className={inputClasses}
            placeholder={placeholder}
          />
          <span className="password-icon" aria-hidden="true">
            <Icon
              size="sm"
              icon={`it-password-${this.state.icon ? "visible" : "invisible"}`}
              className="password-icon-visible"
              onClick={this.toggleShow}
            />
          </span>
        </InputContainer>
      );
    }
    if (normalized) {
      return (
        <InputContainer {...containerProps}>
          <Tag
            {...attributes}
            {...sharedAttributes}
            className={inputClasses}
            readOnly
          />
        </InputContainer>
      );
    }
    if (inputFile) {
      const value = get(attributes, "value.name");
      return (
        <div className="form-group">
          <label
            htmlFor={id}
            style={{
              marginTop: -36,
              fontSize: "0.777rem",
              color: this.props.invalid ? "#d9364f" : "",
            }}
          >
            {label}
          </label>
          <Tag
            {...attributes}
            ref={innerRef}
            className={inputClasses}
            {...sharedAttributes}
            value={value}
            accept={accept.join(",")}
            style={{ overflow: "hidden" }}
            onChange={this.handleFileChange.bind(this)}
          />
        </div>
      );
    }
    if (label || infoText) {
      return (
        <InputContainer {...containerProps}>
          <Tag {...attributes} {...sharedAttributes} className={inputClasses} />
        </InputContainer>
      );
    }

    return (
      <Tag
        {...attributes}
        ref={innerRef}
        className={inputClasses}
        {...sharedAttributes}
      />
    );
  }
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
