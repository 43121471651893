import React from "react";
import { LinkList, Container, Row, Col } from "design-react-kit";
import NodeLinkListItem from "../../components/common/NodeLinkListItem";

const List = ({ list }) => (
  <Container tag="div">
    <Row>
      <Col lg={12}>
        <LinkList multiline tag="div" className="list">
          {list.map((node, index) => (
            <NodeLinkListItem key={index} node={node} />
          ))}
        </LinkList>
      </Col>
    </Row>
  </Container>
);
export default List;
