import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Icon,
} from "design-react-kit";
import { get } from "lodash";
import SimpleBlocks from "./SimpleBlocks";
import EmptyMessae from "../EmptyMessage";

const AccordionNode = ({ isActive, onToggle, expanded, node }) => {
  const active = isActive(node);
  const children = active ? get(node, "children", []) : [];
  const contentType = get(node, "content_type", "node");

  return [
    <AccordionHeader
      key="header"
      active={active}
      onToggle={onToggle.bind(this, node)}
      tag="div"
    >
      <Icon
        size="sm"
        icon={
          contentType === "node"
            ? active
              ? "it-minus"
              : "it-plus"
            : active
            ? "it-collapse"
            : "it-expand"
        }
      />
      {node.name}
    </AccordionHeader>,

    <AccordionBody key="body" active={active}>
      {contentType === "simple_block" && (
        <SimpleBlocks className="" data={[node]} />
      )}
      {contentType !== "simple_block" &&
        children.map((child) => (
          <AccordionNode
            key={child.uuid}
            onToggle={onToggle}
            isActive={isActive}
            expanded={expanded}
            node={child}
          />
        ))}
      {contentType !== "simple_block" && children.length === 0 && (
        <EmptyMessae uuid={get(node, "uuid")} />
      )}
    </AccordionBody>,
  ];
};

AccordionNode.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isActive: PropTypes.func.isRequired,
  expanded: PropTypes.any.isRequired,
  node: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

const Tree = ({ data }) => {
  const [active, setActive] = useState([]);
  const isActive = (node) => active.indexOf(get(node, "uuid")) !== -1;
  const [expanData, setExpandData] = useState({});
  const handleToggle = (node) => {
    const isActive = active.indexOf(node.uuid) === -1;
    setActive(
      !isActive
        ? active.filter((uuid) => uuid !== node.uuid)
        : active.concat([node.uuid])
    );
    setExpandData({ ...expanData, [node.uuid]: true });
  };

  return (
    <Row tag="div" className="px-3">
      <Col lg={12}>
        <Accordion className="accordion-tree">
          {data.map((root) => (
            <AccordionNode
              key={root.uuid}
              expanded={expanData}
              onToggle={handleToggle}
              isActive={isActive}
              node={root}
            />
          ))}
        </Accordion>
      </Col>
    </Row>
  );
};
export default Tree;
