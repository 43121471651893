import React, { useMemo } from "react";

import Breadcrumbs from "../../components/Breadcrumbs";
import List from "./List";
import Loader from "../../components/Loader";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import { get } from "lodash";
import usePage from "../usePage";

const StickyNodesPage = (props) => {
  const page = usePage("stickyNodes", {}, false);
  const list = useMemo(() => get(page, "sticky_nodes", []), [page]);

  if (page === false) {
    return <Loader />;
  }

  return (
    <>
      <Breadcrumbs
        {...props}
        items={[
          {
            to: "/",
            label: "website.navbar.home",
          },
          {
            to: "/contenuti-in-evidenza",
            label: "website.navbar.sticky_nodes",
          },
        ]}
      />
      <Section className="pt-0">
        <SectionTitle title="website.navbar.sticky_nodes" />
        <List list={list} />
      </Section>
    </>
  );
};

export default StickyNodesPage;
