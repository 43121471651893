import React from "react";
import { get } from "lodash";
import { useTranslate } from "react-admin";
import PropTypes from "prop-types";
import { Icon } from "design-react-kit";
import parseLink from "../parseLink";

const SmartList = ({
  size = "large",
  items = [],
  title = undefined,
  type = "list",
}) => {
  const translate = useTranslate();
  if (items.length === 0) {
    return null;
  }
  return (
    <div className={`link-list-wrapper smart-list smart-list-${size}`}>
      <ul className="link-list">
        {title && (
          <li>
            <h3>{translate(title)}</h3>
          </li>
        )}
        {items.map((item, i) => (
          <li key={i}>
            <a
              className="list-item"
              href={parseLink(get(item, "url"), type === "docs")}
              rel="noopener noreferrer"
              target="_blank"
            >
              <h5>
                <Icon
                  icon={type === "docs" ? "it-file" : "it-link"}
                  size="small"
                />
                {get(item, "name")}
                {type === "docs" && <small> ({get(item, "doc_type")})</small>}
              </h5>

              <p>{get(item, "description")}</p>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

SmartList.propTypes = {
  type: PropTypes.oneOf(["links", "docs"]).isRequired,
  size: PropTypes.oneOf(["small", "large"]),
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SmartList;
