import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import { useTranslate } from "react-admin";
import { useLocation } from "react-router-dom";
import { Button, Col, Container, Row } from "design-react-kit";
import usePage from "../usePage";
import Loader from "../../components/Loader";

const HelpPage = (props) => {
  const { pathname, search } = useLocation();
  const translate = useTranslate();
  const page = usePage("help", {}, false);
  if (page === false) {
    return <Loader />;
  }
  const queryString = new URLSearchParams(search);
  const type = queryString.get("type");
  return (
    <>
      <Breadcrumbs
        {...props}
        items={[
          {
            to: "/",
            label: "website.navbar.home",
          },
          {
            to: pathname,
            label: `pages.help.${type}.title`,
          },
        ]}
      />
      <Section className="pt-0">
        <SectionTitle title={`pages.help.${type}.title`} args={page} />
        <Container>
          <Row className="justify-content-md-center">
            <Col lg={8} md={10} sm={12} xs={12}>
              <p>{translate(`pages.help.${type}.message`, page)}</p>
              {type === "access-requested" && (
                <>
                  <Button color="primary" href="/logout">
                    Logout
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default HelpPage;
