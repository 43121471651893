import PropTypes from "prop-types";
import React from "react";
import SmartList from "./SmartList";
import { get } from "lodash";
import moment from "moment";
import { useTranslate } from "react-admin";

const shouldSkipRender = (defType, isMandatoryOnNegativeAlert, cell, negativeAlertHasBeenSet) => {
  if(!negativeAlertHasBeenSet){
    return false;
  }

  switch (defType) {
    case "one_doc":
      return negativeAlertHasBeenSet
              && !isMandatoryOnNegativeAlert
              && (
                !get(cell, "doc.name")
                && !get(cell, "doc.description")
                && !get(cell, "doc.url")
              );
    case "many_docs":
      return negativeAlertHasBeenSet
              && !isMandatoryOnNegativeAlert
              && get(cell, "docs", []).length == 0;
    case "one_link":
      return negativeAlertHasBeenSet
              && !isMandatoryOnNegativeAlert
              && (
                !get(cell, "link.name")
                && !get(cell, "link.description")
                && !get(cell, "link.url")
              );
    case "many_links":
      return negativeAlertHasBeenSet
              && !isMandatoryOnNegativeAlert
              && get(cell, "links", []).length == 0;
    default:
      return negativeAlertHasBeenSet
              && !isMandatoryOnNegativeAlert
              && (
                cell.raw_value == null
                || cell.raw_value == ''
              );
  }
}

const TableRowCell = ({ expanded, column, cell, size = "small", negativeAlertHasBeenSet = false }) => {
  const translate = useTranslate();
  const defType = get(column, "def_type");
  const isMandatoryOnNegativeAlert = get(column, "is_mandatory_on_negative_alert");

  if (!cell) {
    return "";
  }

  if(shouldSkipRender(defType, isMandatoryOnNegativeAlert, cell, negativeAlertHasBeenSet)) {
    return "";
  }

  switch (defType) {
    case "date":
      if (expanded) {
        return (
          <>
            <strong>{get(column, "name")}</strong>
            <br />
            {moment(cell.raw_value).format(
              translate("website.date.format.short")
            )}
          </>
        );
      }
      return moment(cell.raw_value).format(
        translate("website.date.format.short")
      );
    case "email":
      if (expanded) {
        return (
          <>
            <strong>{get(column, "name")}</strong>
            <br />
            <a href={`mailto:${cell.raw_value}`}>{cell.raw_value}</a>;
          </>
        );
      }
      return <a href={`mailto:${cell.raw_value}`}>{cell.raw_value}</a>;
    case "one_doc":
    case "many_docs":
      return (
        <SmartList
          type="docs"
          size={size}
          title={get(column, "name")}
          items={
            defType === "many_docs" ? get(cell, "docs") : [get(cell, "doc")]
          }
        />
      );
    case "one_link":
    case "many_links":
      return (
        <SmartList
          type="links"
          title={get(column, "name")}
          size={size}
          items={
            defType === "many_links" ? get(cell, "links") : [get(cell, "link")]
          }
        />
      );
    default:
      if (size === "small") {
        if (expanded) {
          return (
            <>
              <strong>{get(column, "name")}</strong>
              <br />
              <label>{get(cell, "raw_value")}</label>
            </>
          );
        }
        return get(cell, "raw_value");
      } else {
        return (
          <span>
            <strong>{get(column, "name")}</strong>
            <br />
            <label>{get(cell, "raw_value")}</label>
          </span>
        );
      }
  }
};

const docPropTypes = PropTypes.shape({
  description: PropTypes.string.isRequired,
  doc_type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});
const linkPropTypes = PropTypes.shape({
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});
TableRowCell.propTypes = {
  expanded: PropTypes.bool,
  column: PropTypes.shape({
    def_type: PropTypes.string.isRequired,
    is_filterable: PropTypes.bool,
    is_preview: PropTypes.bool,
    name: PropTypes.string.isRequired,
    order_index: PropTypes.number.isRequired,
  }),
  cell: PropTypes.shape({
    raw_value: PropTypes.string,
    doc: docPropTypes,
    docs: PropTypes.arrayOf(docPropTypes),
    link: linkPropTypes,
    links: PropTypes.arrayOf(linkPropTypes),
  }),
  negativeAlertHasBeenSet: PropTypes.bool
};

export default TableRowCell;
