import React from "react"; // , { useState }
import { /*Icon, Button, */ Row, Col, Container } from "design-react-kit";
// import { useTranslate } from "react-admin";
import RootCard from "./RootCard";

const Roots = ({ roots }) => {
  // const [expanded, setExpanded] = useState(false);
  // const handleExpand = () => setExpanded(!expanded);
  // const translate = useTranslate();
  return (
    <Container tag="div">
      <Row tag="div">
        {roots
          // .filter((_, i) => (expanded ? true : i < 9))
          .map((root) => (
            <Col key={root.uuid} lg={3} md={4} sm={12} xs={12} className="mb-2">
              <RootCard root={root} />
            </Col>
          ))}
      </Row>
      {/* <Row tag="div">
        <Col lg={12} className="text-right">
          <Button
            color="link"
            icon={false}
            outline
            tag="button"
            onClick={handleExpand}
          >
            {translate(
              `pages.home.buttons.${expanded ? "collapse" : "expand"}`
            )}
            <Icon icon={expanded ? "it-collapse" : "it-expand"} />
          </Button>
        </Col>
      </Row> */}
    </Container>
  );
};

export default Roots;
