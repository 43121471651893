import { Col, Container, Row } from "design-react-kit";
import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
const PrivacyPolicyPage = (props) => {
  return (
    <>
      <Breadcrumbs
        {...props}
        items={[
          {
            to: "/",
            label: "website.navbar.home",
          },
          {
            to: "/privacy-policy",
            label: "website.navbar.privacy_policy",
          },
        ]}
      />
      <Section className="pt-0">
        <SectionTitle title="website.navbar.privacy_policy" />
        <Container tag="div">
          <Row className="justify-content-md-center">
            <Col lg={10} md={12}>
              <p className="text-justify">
                Il trattamento dei dati personali contenuti nel portale internet
                della Regione Siciliana è effettuato in applicazione del
                Regolamento UE 2016/679 del Parlamento Europeo e del Consiglio
                del 27/4/2016, in materia di protezione dei dati personali e
                delle disposizioni di cui al d.lgs. 196/2003 e le indicazioni
                contenute nelle "Linee guida in materia di trattamento di dati
                personali contenuti anche in atti e documenti amministrativi,
                effettuato da soggetti pubblici per finalità di pubblicazione e
                diffusione sul web", adottate con provvedimento n. 88 del 2
                marzo 2011 del Garante per la Protezione dei dati personali.
              </p>

              <p className="text-justify">
                Secondo la predetta normativa, il trattamento è improntato
                rispetto ai principi di correttezza, liceità, pertinenza e
                trasparenza e tutelando la riservatezza e i diritti
                dell'interessato ai sensi di legge.
              </p>

              <p className="text-justify">
                Ai sensi dell'art. 10 della predetta legge vengono indicate le
                seguenti informazioni.
              </p>

              <p className="text-justify">
                Il Testo Unico sulla Privacy prevede una serie di obblighi e di
                adempimenti da parte di coloro i quali - siano essi il
                "Titolare", il "Responsabile" e/o gli "Incaricati" ai sensi
                dall'art. 4 lett. f, g, h, del D.Lgs. 30 giugno 2003, n. 196 -
                effettuano il "trattamento" dei dati personali - ai sensi
                dall'art. 4 lett. a del predetto Decreto Legislativo: raccolta,
                registrazione, elaborazione, conservazione, comunicazione,
                diffusione, ecc. - riferiti ad altri soggetti c.d. "interessati"
                ai sensi dall'art. 4 lett. i, del medesimo Decreto Legislativo.
                Il trattamento dei dati personali sarà effettuato unicamente per
                finalità istituzionali di seguito descritte. I dati forniti sono
                obbligatori per abilitare l'accesso e l'utilizzo dei servizi
                erogati dal "Portale Internet" della Regione Siciliana. Nessun
                dato derivante dal servizio web viene comunicato o diffuso,
                salvo nei casi espressamente previsti dalla legge. I dati
                personali forniti dagli utenti sono utilizzati al solo fine di
                fruire del servizio o della prestazione richiesta e sono
                comunicati a terzi solo nel caso in cui ciò sia a tal fine
                necessario.
              </p>

              <p className="text-justify">
                Nella tipologia dei dati trattati ci sono anche quelli di
                "navigazione internet" acquisiti nel corso del normale utilizzo
                del portale, ivi inclusi i dati personali la cui trasmissione è
                implicita nell'uso dei protocolli di comunicazione impiegati.
                Tali informazioni non sono acquisite per identificare gli
                interessati, ma potrebbero essere utilizzate, in tal senso,
                attraverso elaborazione ed associazione con dati detenuti da
                terzi. In questa categoria rientrano gli indirizzi IP o i nomi
                di dominio dei computer utilizzati dagli utenti che si collegano
                al sito, gli indirizzi in notazione URI (Uniform Resource
                Identifier) delle risorse richieste, l'orario della richiesta,
                il metodo utilizzato nel sottoporre la richiesta al server, la
                dimensione del file ottenuto in risposta, il codice numerico
                indicante lo stato della risposta data dal server ed altri
                parametri relativi al sistema operativo e all'ambiente
                informatico dell'utente. Questi dati sono utilizzati al solo
                fine di ricavare informazioni statistiche anonime sull'utilizzo
                del sito e per controllare il corretto funzionamento dello
                stesso; sono mantenuti per il periodo minimo richiesto dalla
                normativa vigente e comunicati o diffusi solo nei casi
                espressamente previsti dalla legge. Nessun dato personale degli
                utenti è acquisito mediante cookie di sessione. Non è fatto uso
                di cookie per le informazioni di carattere personale, né sono
                utilizzati cookie di sessione persistenti di alcun tipo ovvero
                sistemi per il tracciamento utenti. L'uso dei cookie di sessione
                è strettamente limitato alla trasmissione di identificativi di
                sessione necessari per consentire l'esplorazione sicura ed
                efficiente del sito stesso e per evitare il ricorso ad altre
                tecniche informatiche potenzialmente pregiudizievoli per la
                riservatezza della navigazione degli utenti. L'invio facoltativo
                esplicito e volontario dei dati mediante messaggi di posta
                elettronica (c.d. e-mail), con l'utilizzo di form dedicate
                all'uopo, comporta l'inevitabile acquisizione di eventuali altri
                dati personali inseriti nella missiva. Qualora, quindi, in
                queste e-mail, vi fossero dati sensibili e/o giudiziari, questi
                saranno cancellati. Pertanto, è escluso il trattamento di dati
                sensibili e giudiziari che, se forniti dall'utente, saranno
                rimossi. Il Titolare del Trattamento è a conoscenza che i
                suddetti dati, forniti dall'interessato, sono solo di carattere
                anagrafico e/o identificativo e/o personale e/o di navigazione.
                Il trattamento dei suddetti dati avverrà con l'ausilio di
                procedure informatizzate e/o manuali e/o sistemi automatizzati
                atti a memorizzare, gestire e/o trasmettere gli stessi, nei
                limiti necessari per perseguire le predette finalità.
                L'interessato è chiamato a comunicare tempestivamente eventuali
                correzioni, integrazioni e/o aggiornamenti dei dati personali
                ivi registrati. I medesimi dati potranno essere comunicati o
                portati a conoscenza di responsabili e/o incaricati di altri
                soggetti pubblici o incaricati di pubblico servizio, che debbano
                partecipare al procedimento amministrativo, anche a seguito di
                eventuali ispezioni o verifiche. Il conferimento dei predetti
                dati è necessario per poter concludere positivamente il processo
                di registrazione. In caso di rifiuto degli stessi o ad
                acconsentire al loro trattamento, compresa la loro comunicazione
                nei limiti sopra indicati, ne deriva l'impossibilità di accesso
                ai servizi previsti dal succitato portale istituzionale.
              </p>

              <p className="text-justify">
                Ai sensi dell'art.7 del D.Lgs. 30 giugno 2003, n. 196
                l'interessato ha la possibilità di esercitare alcuni diritti con
                richiesta rivolta senza formalità, anche mediante lettera
                raccomandata, telefax o posta elettronica (art.9 del D.Lgs. 30
                giugno 2003, n.196) al Titolare o al Responsabile del
                Trattamento, in particolare:
              </p>
              <ul>
                <li>
                  conoscere l'esistenza o meno dei propri dati personali e la
                  loro comunicazione in forma intelligibile;
                </li>
                <li>
                  essere informato sul Titolare del Trattamento, sulle finalità
                  e sulle modalità del trattamento e sull'eventuale
                  responsabile, sui soggetti o categorie di soggetti ai quali i
                  dati personali possono essere comunicati;
                </li>
                <li>
                  ottenere l'aggiornamento, la rettificazione o l'integrazione
                  dei dati;
                </li>
                <li>
                  ottenere la cancellazione, la trasformazione in forma anonima
                  o il blocco degli stessi;
                </li>
                <li>
                  opporsi per motivi legittimi al trattamento dei dati, salvi i
                  limiti stabiliti dalla legge;
                </li>
                <li>
                  opporsi all'invio di materiale pubblicitario o per il
                  compimento di ricerche di mercato o di comunicazione
                  commerciale.
                </li>
              </ul>
              <p className="text-justify">
                Il testo completo dell'art. 7 relativo ai diritti
                dell'interessato, dell'articolo 13 relativo all'informativa e
                gli articoli 23, 26 e 27 relativi al consenso e alle garanzie,
                sono disponibili sul sito www.garanteprivacy.it.
              </p>

              <p className="text-justify">
                Ai sensi dell'art. 56, L.R. 27 aprile 1999, n. 10, il Titolare
                del Trattamento dei dati personali è l'Assessorato Regionale
                dell'Economia, nella persona dell'Assessore pro-tempore, con
                sede in Via Notarbartolo, 17/a - 90141 Palermo.
              </p>

              <p className="text-justify">
                Si precisa, altresì, che l'Assessorato dell'Economia della
                Regione Siciliana non ha alcuna responsabilità per quanto
                riguarda i siti esterni e/o i siti istituzionali e/o tematici
                ascrivibili anche ad altri uffici della Regione, ai quali è
                possibile accedere tramite i collegamenti posti all'interno
                della pagina iniziale del sito stesso.
              </p>

              <p className="text-justify">
                In merito, sono da ritenersi responsabili i soggetti Titolari
                dei siti citati, ai quali si accede alle condizioni dagli stessi
                previste.
              </p>

              <p className="text-justify">
                Per esercitare i diritti di cui all'art.7 sopra indicato, dovrà
                rivolgere richiesta scritta indirizzata al relativo Titolare del
                Trattamento.
              </p>

              <p className="text-justify">
                Il Regolamento UE 2016/679 del Parlamento Europeo e del
                Consiglio del 27/4/2016, in materia di protezione dei dati
                personali, entrato in vigore il 25.5.2018, ha previsto
                l'istituzione della figura del Responsabile della protezione dei
                dati (RPD) o "Data Protection Officier (DPO).
              </p>

              <p className="text-justify">
                Per contattare il RPD scrivere a:{" "}
                <a href="mailto:dpo@regione.sicilia.it">
                  dpo@regione.sicilia.it
                </a>
              </p>

              <p className="text-justify">
                Per ulteriori informazioni:
                <br />
                <a href="http://pti.regione.sicilia.it/portal/page/portal/PIR_PORTALE/PIR_LaStrutturaRegionale/PIR_PresidenzadellaRegione/responsabile-protezione-dati">
                  Pagina di dettaglio - Responsabile protezione dati (RPD/DPO)
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default PrivacyPolicyPage;
