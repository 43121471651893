import {
  Card,
  CardBody,
  CardReadMore,
  CardSubtitle,
  CardTitle,
  Icon,
} from "design-react-kit";

import CardText from "../../components/CardText";
import Link from "../../components/Link";
import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";

const HeaderCard = ({
  title,
  subtitle1,
  subtitle2,
  content1,
  content2,
  bodyLink,
  readmoreLink,
  icon,
}) => (
  <Card className="card-bg card-big card-header" noWrapper={false} tag="div">
    <div className="flag-icon" />
    <CardBody>
      {icon && <Icon name={icon} padding={false} size={"200"} />}
      {title && <CardTitle tag="h4">{title}</CardTitle>}
      {subtitle1 && <CardSubtitle tag="h5">{subtitle1}</CardSubtitle>}
      {subtitle2 && <CardText tag="h6">{subtitle2}</CardText>}
      {content1 && (
        <CardText
          dangerouslySetInnerHTML={{
            __html: content1,
          }}
        />
      )}
      {content2 && (
        <CardText
          tag="p"
          dangerouslySetInnerHTML={{
            __html: content2,
          }}
        />
      )}
      {bodyLink && (
        <Link className="law-link" href={get(bodyLink, "href")}>
          {get(bodyLink, "name")}
        </Link>
      )}
      {readmoreLink && (
        <CardReadMore
          href={get(readmoreLink, "href")}
          target="_blank"
          iconName="it-arrow-right"
          tag="a"
          text={get(readmoreLink, "name")}
        />
      )}
    </CardBody>
  </Card>
);

HeaderCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle1: PropTypes.string,
  subtitle2: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  bodyLink: PropTypes.shape({
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  readmoreLink: PropTypes.shape({
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  icon: PropTypes.string,
};

export default HeaderCard;
