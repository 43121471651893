import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";
import CookiePolicyBar from "./CookiePolicyBar";
import { FontLoader } from "design-react-kit";

const Layout = ({ children, ...props }) => {

  setTimeout(() => {
    document.getElementById('root').firstChild.style.zIndex = 3;
  }, 250);

  return (
    <>
      <FontLoader />
      <Header sticky/>
      <main>{children}</main>
      <Footer />
      <CookiePolicyBar />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
