import { InlineShareButtons } from "sharethis-reactjs";
import React from "react";
import { get } from "lodash";
import makeid from "../utils/makeid";

const ShareThis = ({ url, ...props }) => {
  const finalUrl = url
    ? url.replace(
        "//amministrazionetrasparente",
        "https://amministrazionetrasparente"
      )
    : undefined;
  return (
    <div className="sharethis" data-url={get(props, "url")}>
      <InlineShareButtons
        key={makeid(10)}
        config={{
          alignment: "right", // alignment of buttons (left, center, right)
          color: "white", // set the color of buttons (social, white)
          enabled: true, // show/hide buttons (true, false)
          font_size: 16, // font size for the buttons
          labels: "null", // button labels (cta, counts, null)
          language: "it", // which language to use (see LANGUAGES)
          networks: ["facebook", "twitter", "email"],
          padding: 12, // padding within buttons (INTEGER)
          radius: 4, // the corner radius on each button (INTEGER)
          show_total: false,
          size: 40, // the size of each button (INTEGER)
          url: finalUrl,
          ...props,
          // OPTIONAL PARAMETERS
          //   url: "https://www.sharethis.com", // (defaults to current url)
          //   image: "https://bit.ly/2CMhCMC", // (defaults to og:image or twitter:image)
          //   description: "custom text", // (defaults to og:description or twitter:description)
          //   title: "custom title", // (defaults to og:title or twitter:title)
          //   message: "custom email text", // (only for email sharing)
          //   subject: "custom email subject", // (only for email sharing)
          //   username: "custom twitter handle", // (only for twitter sharing)
        }}
      />
    </div>
  );
};
export default ShareThis;
