import { Col, Container, Row } from "design-react-kit";
import React from "react";
import { useTranslate } from "react-admin";
const Loader = () => {
  const translate = useTranslate();
  return (
    <Container tag="div">
      <Row tag="div">
        <Col tag="div" lg={12} className="px-4 py-4 loader">
          <div className="progress-spinner progress-spinner-double progress-spinner-active">
            <div className="progress-spinner-inner"></div>
            <div className="progress-spinner-inner"></div>
            <span className="sr-only">{translate("website.loader")}</span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Loader;
