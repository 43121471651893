import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from "design-react-kit";
import React, { useMemo } from "react";

import CardText from "../../../components/CardText";
import PropTypes from "prop-types";
import SmartList from "./SmartList";
import { get } from "lodash";
import moment from "moment";
import { useTranslate } from "react-admin";

// import parseContent from "../parseContent";
// import { BASE_URL } from "../../../config";
// import ShareThis from "../../../components/ShareThis";

const reduceData = (data) =>
  data.reduce(
    (simpleBlocks, content) =>
      simpleBlocks.concat(
        content?.simple_block_items
          ?.map((sbi) => ({
            ...sbi,
            uuid: get(content, "uuid"),
            published: get(content, "published"),
            modified: get(content, "modified"),
          }))
          .sort((a, b) => a.order_index - b.order_index)
      ),
    []
  );

const SimpleBlocks = ({ data, className = "px-3" }) => {
  const simpleBlocks = useMemo(() => reduceData(data), [data]);
  const translate = useTranslate();
  const dateFormat = useMemo(
    () => translate("website.date.format.short"),
    [translate]
  );
  return (
    <Row tag="div" className={className}>
      {simpleBlocks.map((simpleBlock, i) => (
        <Col lg={12} key={i} className="mt-3">
          <Card className="card-bg card-content-type">
            <CardBody>
              <CardTitle tag="h4">{get(simpleBlock, "name")}</CardTitle>
              {get(simpleBlock, "expiry_date", null) !== null && (
                <CardText className="sub">
                  {translate("pages.node.simple_blocks.expiry", {
                    date: moment(get(simpleBlock, "expiry_date")).format(
                      dateFormat
                    ),
                  })}
                </CardText>
              )}
              <CardText tag="div">
                <div
                  dangerouslySetInnerHTML={{
                    __html: /* parseContent(*/ get(
                      simpleBlock,
                      "description"
                    ) /*),*/,
                  }}
                />
              </CardText>
              <SmartList
                type="links"
                title="pages.node.simple_blocks.links"
                items={get(simpleBlock, "links", [])}
              />
              <SmartList
                type="docs"
                title="pages.node.simple_blocks.docs"
                items={get(simpleBlock, "docs", [])}
              />
            </CardBody>
            <CardFooter className="footer-fix">
              <CardText tag="small">
                {/* {translate("pages.node.published", {
                  date: moment(get(simpleBlock, "published")).format(
                    dateFormat
                  ),
                })}
                {" | "}
                {translate("pages.node.modified", {
                  date: moment(get(simpleBlock, "modified")).format(dateFormat),
                })}
                <br /> */}
                {get(simpleBlock, "tags", []).map((tag, i) => (
                  <a key={i} href={`/tag/${tag}`}>
                    {tag.startsWith("#") ? tag : `#${tag}`}
                  </a>
                ))}
              </CardText>

              {/* <ShareThis
                url={`${BASE_URL}/node?uuid=${get(simpleBlock, "uuid")}`}
              /> */}
            </CardFooter>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

const ResourceProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

SimpleBlocks.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      simple_blocks: PropTypes.arrayOf({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string),
        docs: PropTypes.arrayOf(ResourceProps),
        links: PropTypes.arrayOf(ResourceProps),
      }),
    })
  ),
};

export default SimpleBlocks;
