import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import { useLocation } from "react-router-dom";

const ErrorPage = (props) => {
  const { pathname } = useLocation();
  let errorCode = pathname.substr(1);
  if (["error403", "error404"].indexOf(errorCode) === -1) {
    errorCode = "error404";
  }
  return (
    <>
      <Breadcrumbs
        {...props}
        items={[
          {
            to: "/",
            label: "website.navbar.home",
          },
          {
            to: pathname,
            label: `website.navbar.${errorCode}`,
          },
        ]}
      />
      <Section className="pt-0">
        <SectionTitle title={`pages.${errorCode}.message`} />
      </Section>
    </>
  );
};

export default ErrorPage;
